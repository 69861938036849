import React, { useState, useEffect } from "react";
import { IoMdSync } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import TransferCountrySelect from "../components/TransferCountrySelect";
import TransferNumberInput from "../components/TransferNumberInput";
import { Loader } from "../components/utils";
import { checkUser, toast } from "../helpers";
import { getCountriesPublic, getExchangeRatesPublic } from "../services/apiService";

const TransferForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fromCountry, setFromCountry] = useState(null);
    const [toCountry, setToCountry] = useState(null);
    const [amount, setAmount] = useState("50");
    const [exchangeRates, setExchangeRates] = useState(null);
    const [currentExchangeRate, setCurrentExchangeRate] = useState("");
    const [receivedAmount, setReceivedAmount] = useState("0.00");
    const [debouncedAmount, setDebouncedAmount] = useState("50");
    const [isCalculating, setIsCalculating] = useState(false);

    // Fetch countries and set defaults
    useEffect(() => {
        const setDefaultCountries = async () => {
            try {
                const response = await getCountriesPublic();
                const countriesData = response.data;

                const usa = countriesData.find(
                    country =>
                        country.country_code === "US" ||
                        country.country_name.trim().toUpperCase() === "UNITED STATES"
                );
                const kenya = countriesData.find(
                    country =>
                        country.country_code === "KE" ||
                        country.country_name.trim().toUpperCase() === "KENYA"
                );

                if (usa) setFromCountry(usa);
                if (kenya) setToCountry(kenya);
            } catch (error) {
                console.error("Error setting default countries:", error);
                //toast("error", "Failed to fetch countries");  // Commented out error toast
            }
        };

        setDefaultCountries();
    }, []);

    // Fetch exchange rates
    useEffect(() => {
        const fetchExchangeRates = async () => {
            try {
                const response = await getExchangeRatesPublic();
                let exchangeRate = [];
                for (const [key, value] of Object.entries(response.data)) {
                    exchangeRate.push({ key, value });
                }
                exchangeRate.push({ key: "USDUSD", value: 1 });
                setExchangeRates(exchangeRate);
            } catch (error) {
                console.error(`exchange rate: ${error}`);
                // toast("error", "Failed to fetch exchange rates");
            }
        };
        fetchExchangeRates();
    }, []);

    // Debounce amount changes
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedAmount(amount);
        }, 500);

        return () => clearTimeout(timer);
    }, [amount]);

    // Calculate received amount
    useEffect(() => {
        const calculateAmount = async () => {
            if (fromCountry && toCountry && debouncedAmount && exchangeRates && !isCalculating) {
                setIsCalculating(true);
                try {
                    const amountInFromCurrency = parseFloat(debouncedAmount.replace(/,/g, ""));
                    const fromCountryCurrencyCode = fromCountry?.currency_code.trim();
                    const toCountryCurrencyCode = toCountry?.currency_code.trim();

                    const fromExchangeRate = exchangeRates.find(
                        rate => rate.key === `USD${fromCountryCurrencyCode}`
                    );
                    const toExchangeRate = exchangeRates.find(
                        rate => rate.key === `USD${toCountryCurrencyCode}`
                    );

                    let convertAmountToSendToUsd = amountInFromCurrency / fromExchangeRate?.value;
                    let convertUsdToAmountToReceive =
                        convertAmountToSendToUsd * toExchangeRate?.value;

                    parseFloat(convertUsdToAmountToReceive).toFixed(2) < 0
                        ? setReceivedAmount("0")
                        : setReceivedAmount(parseFloat(convertUsdToAmountToReceive).toFixed(2));

                    setCurrentExchangeRate(
                        `1 ${fromCountry?.currency_code} = ${parseFloat(
                            (1 / fromExchangeRate?.value) * toExchangeRate?.value
                        ).toFixed(2)} ${toCountry?.currency_code}`
                    );
                } catch (error) {
                    console.error("Error calculating amount:", error);
                    setReceivedAmount("0.00");
                } finally {
                    setIsCalculating(false);
                }
            }
        };

        calculateAmount();
    }, [fromCountry, toCountry, debouncedAmount, exchangeRates, isCalculating]);

    const submit = () => {
        if (!checkUser()) {
            toast("error", "This action requires you to be connected");
            navigate("/login");
            return;
        }

        if (!fromCountry || !toCountry || !amount) {
            toast("error", "Please fill in all required fields");
            return;
        }

        setLoading(true);
        // Simulate API call
        setTimeout(() => {
            toast("success", "Successful transfer");
            setAmount("");
            setLoading(false);
        }, 3000);
    };

    return (
        <>
            <div className="rounded-lg bg-indigo-50 p-4 lg:p-5">
                <div className="flex space-x-3 items-center">
                    <div className="flex-1">
                        <h6 className="text-sm text-indigo-900">From</h6>
                        <TransferCountrySelect
                            id="fromCountry"
                            setSelectedOption={setFromCountry}
                            necessary={true}
                            defaultCountry={{
                                country_code: "US",
                                country_name: "United States",
                                currency_code: "USD"
                            }}
                        />
                    </div>

                    <div className="w-1/3">
                        <h6 className="text-sm text-indigo-900">You Send</h6>
                        <TransferNumberInput
                            formattedValue={amount}
                            setFormattedValue={setAmount}
                        />
                    </div>
                </div>
            </div>

            <div className="space-y-3 lg:space-y-5 text-indigo-900">
                <div className="flex items-center space-x-4 text-indigo-600 text-sm">
                    <IoMdSync className="w-5 h-5" />
                    <span>Exchange Rate Details</span>
                </div>

                <div className="flex items-center">
                    <div className="w-1/2">Exchange Rate</div>
                    <div className="w-1/2">
                        <span>
                            {isCalculating ? (
                                <span className="text-gray-400">Calculating...</span>
                            ) : (
                                currentExchangeRate || "Select countries to see rate"
                            )}
                        </span>
                    </div>
                </div>
            </div>

            <div className="rounded-lg bg-indigo-50 p-4 lg:p-5">
                <div className="flex space-x-3 items-center">
                    <div className="flex-1">
                        <h6 className="text-sm text-indigo-900">To</h6>
                        <TransferCountrySelect
                            id="toCountry"
                            setSelectedOption={setToCountry}
                            necessary={true}
                            defaultCountry={{
                                country_code: "KE",
                                country_name: "Kenya",
                                currency_code: "KES"
                            }}
                        />
                    </div>
                    <div className="w-1/3">
                        <h6 className="text-sm text-indigo-900">Recipient Gets</h6>
                        <div className="text-xl font-bold text-indigo-900">
                            {isCalculating ? (
                                <span className="text-gray-400">Calculating...</span>
                            ) : (
                                `${receivedAmount} ${toCountry?.currency_code || ""}`
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <button
                onClick={submit}
                className="w-full items-center justify-center space-x-3 transition-all block text-center duration-300 px-3 lg:px-4 xl:px-8 font-medium lg:text-lg py-3 bg-indigo-600 text-white rounded-md focus:outline-none hover:bg-indigo-700 focus:ring focus:border-indigo-500 focus:ring-indigo-500/50"
            >
                {loading && <Loader color={"white"} />}
                <span>Get started</span>
            </button>
        </>
    );
};

export default TransferForm;
