import { PageAnimation } from "../components/utils";
import useIdleTimer from "../hooks/useIdleTimer";

import AdminFooter from "./AdminFooter";

const AdminLayout = ({ children }) => {
    useIdleTimer(300000, 60000);

    return (
        <div className="w-full min-h-screen bg-gray-100">
            <div className="flex w-full pt-32">
                <div className="w-full lg:w-full px-5 mt-0.5 transition-all duration-500 ">
                    <PageAnimation>{children}</PageAnimation>

                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
