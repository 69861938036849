import React from "react";

import NumberTextInput from "./NumberTextInput";

const TransferNumberInput = ({ formattedValue, setFormattedValue }) => {
    return (
        <div className="transfer-input relative">
            <NumberTextInput
                placeholder="Enter Amount"
                formattedValue={formattedValue}
                setFormattedValue={setFormattedValue}
            />
            <style>
                {`
                    .transfer-input input {
                        width: 100% !important;
                        padding: 8px 0 !important;
                        background-color: transparent !important;
                        color: #1F2937 !important;
                        font-weight: 500 !important;
                        border: none !important;
                        border-radius: 0 !important;
                    }
                    .transfer-input {
                        border-bottom: 1px solid #E5E7EB !important;
                    }
                    .transfer-input input:focus {
                        outline: none !important;
                    }
                    .transfer-input input::placeholder {
                        color: #6B7280 !important;
                        font-weight: 400 !important;
                    }
                `}
            </style>
        </div>
    );
};

export default TransferNumberInput;
