import React, { useState, useEffect, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import { IoChevronDownOutline } from "react-icons/io5";

import { getCountriesPublic } from "../services/apiService";

const TransferCountrySelect = ({ id, setSelectedOption, necessary, defaultCountry }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(defaultCountry.country_name || "");
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    // Fetch countries based on search term
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountriesPublic(searchTerm);
                setCountries(response.data);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        if (searchTerm) {
            fetchCountries();
        }
    }, [searchTerm]);

    const handleIconClick = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            inputRef.current.focus();
        }
    };

    const handleOptionClick = option => {
        setSelectedOption(option);
        setSelectedCountry(option);
        setSearchTerm(option.country_name);
        setIsOpen(false);
    };

    const handleInputChange = e => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value === "") {
            setSelectedOption(null);
            setSelectedCountry(null);
        }
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const filteredCountries = countries.filter(country =>
        country.country_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="transfer-select relative w-full" ref={dropdownRef}>
            <div className="flex items-center justify-between w-full border-b border-gray-300 pb-2">
                <div className="flex items-center space-x-2 flex-1">
                    {selectedCountry && necessary && (
                        <div className="flex items-center">
                            <ReactCountryFlag
                                countryCode={selectedCountry.country_code}
                                svg
                                className="mr-2"
                                style={{
                                    width: "1.5em",
                                    height: "1.5em"
                                }}
                            />
                            <span className="font-semibold mr-4">
                                {selectedCountry.currency_code}
                            </span>
                        </div>
                    )}
                    <input
                        ref={inputRef}
                        id={id}
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search country"
                        className="w-full border-0 focus:ring-0 focus:border-0 text-lg bg-indigo-50"
                        onClick={handleIconClick}
                    />
                </div>
                <IoChevronDownOutline
                    className={`text-gray-500 text-xl cursor-pointer ml-2 transition-transform duration-200 ${
                        isOpen ? "transform rotate-180" : ""
                    }`}
                    onClick={handleIconClick}
                />
            </div>
            {isOpen && (
                <div
                    className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg"
                    style={{ minWidth: "200px", width: "auto" }}
                >
                    <div className="max-h-60 overflow-y-auto">
                        {filteredCountries.map((option, index) => (
                            <div
                                key={index}
                                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                                onClick={() => handleOptionClick(option)}
                            >
                                <ReactCountryFlag
                                    countryCode={option.country_code}
                                    svg
                                    className="mr-2"
                                    style={{
                                        width: "1.5em",
                                        height: "1.5em"
                                    }}
                                />
                                <span className="font-semibold mr-4">{option.currency_code}</span>
                                <span className="text-lg">{option.country_name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransferCountrySelect;
