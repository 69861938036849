const AuthLayout = ({ title = "", children }) => {
    return (
        <div className="flex text-gray-800 p-4 md:py-6 md:px-32 lg:p-0">
            <div className="flex flex-col-reverse lg:flex-row w-screen lg:min-h-screen border shadow-sm rounded-lg overflow-hidden lg:border-none lg:shadow-none lg:rounded-none lg:overflow-auto">
                <div className="flex flex-col justify-center items-center text-white lg:min-h-screen w-full lg:w-10/12 xl:w-3/5 mx-auto bg-[#111827]">
                    <div className="space-y-8 p-9 w-full">
                        <div className="space-y-4 -mt-20">
                            <h1 className="text-2xl lg:text-4xl font-semibold text-center">
                                {title}
                            </h1>
                            <p className="font-medium text-center">We are glad to see you!</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center lg:min-h-screen p-6 md:p-10 lg:p-8 xl:p-10 w-full max-w-screen-md mx-auto">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
