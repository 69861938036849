import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Container } from "../components/utils";
import { toast } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { activateAccount } from "../services/apiService";

const AccountActivation = () => {
    const [activating, setActivating] = useState(true);
    const [activated, setActivated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const activateUserAccount = async () => {
            if (activated) return;

            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get("code");

            if (!code) {
                toast("error", "Invalid activation link");
                navigate("/login");
                return;
            }

            try {
                const response = await activateAccount(code);
                console.log(response);
                if (response.status === 200) {
                    toast("success", response.description);
                    setActivated(true);
                } else {
                    toast("error", response.description);
                }
            } catch (error) {
                console.error("Activation error:", error);
                toast(
                    "error",
                    error.response?.data?.description || "An error occurred during activation"
                );
            } finally {
                setActivating(false);
                navigate("/login");
            }
        };

        activateUserAccount();
    }, [navigate, location, activated]);

    if (activating) {
        return (
            <>
                <Nav />
                <AdminLayout>
                    <Container>
                        <div className="flex justify-center items-center h-screen">
                            <p>Activating your account...</p>
                        </div>
                    </Container>
                </AdminLayout>
            </>
        );
    }

    return null;
};

export default AccountActivation;
