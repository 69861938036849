import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Container } from "../components/utils";
import { checkUser, logout } from "../helpers";
import { getProfile } from "../services/apiService";

const Nav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getProfile();
                setProfile(response.data);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        if (checkUser()) {
            fetchProfile();
        }
    }, []);

    const handleClick = e => {
        e.preventDefault();
        logout(navigate);
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    // Helper function to check if a link is active
    const isActive = path => {
        return location.pathname === path;
    };

    // Helper function to generate link classes
    const getLinkClasses = path => {
        return `relative transition-all duration-300 font-medium py-1 hover:text-indigo-600 
            ${
                isActive(path)
                    ? "text-indigo-600 after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-indigo-600"
                    : ""
            } 
            group`;
    };

    return (
        <nav className="text-gray-600 text-base xl:text-lg font-medium bg-white py-2 border-b shadow-sm fixed top-0 left-0 w-full z-50">
            <Container className="flex items-center justify-between">
                <div className="flex items-center space-x-4 md:space-x-10">
                    <Link to={checkUser() ? "/dashboard" : "/"} className="flex items-center">
                        <img
                            src="/images/images/linksremitslogo.svg"
                            alt="Logo"
                            className="w-32 h-16 md:w-40 md:h-20"
                        />
                    </Link>

                    {/* Desktop Navigation Links */}
                    <div className="hidden md:flex space-x-8">
                        {checkUser() ? (
                            <>
                                <Link to="/dashboard" className={getLinkClasses("/dashboard")}>
                                    Dashboard
                                </Link>
                                <Link to="/remittance" className={getLinkClasses("/remittance")}>
                                    Send Money
                                </Link>
                                <Link
                                    to="/transactions"
                                    className={getLinkClasses("/transactions")}
                                >
                                    Transactions
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link to="#" className={getLinkClasses("#")}>
                                    How it works
                                </Link>
                                <Link to="#" className={getLinkClasses("#")}>
                                    Help
                                </Link>
                                <Link to="#" className={getLinkClasses("#")}>
                                    Contact Us
                                </Link>
                            </>
                        )}
                    </div>
                </div>

                {/* Desktop Profile Menu */}
                {checkUser() ? (
                    <div className="hidden md:block">
                        <Menu>
                            <div className="relative">
                                <Menu.Button className="focus:outline-none">
                                    <FaUserCircle className="w-8 h-8 text-gray-600 hover:text-gray-800" />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items className="absolute right-0 z-10 w-48 px-2 py-1 mt-1 text-gray-600 bg-white border rounded-md shadow">
                                        <Menu.Item>
                                            <Link
                                                to={"/profile"}
                                                className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-purple-500"
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                                <span>Profile</span>
                                            </Link>
                                        </Menu.Item>
                                        <hr />
                                        <Menu.Item>
                                            <Link
                                                to={"/settings"}
                                                className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-purple-500"
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 8c-1.1 0-2 .9-2 2 0 1.1.9 2 2 2s2-.9 2-2c0-1.1-.9-2-2-2zm0-3c-1.65 0-3.17.84-4.12 2.12l-1.45-.49-1.17 1.17.49 1.45A6.974 6.974 0 005 12c0 .74.12 1.45.34 2.12l-.49 1.45 1.17 1.17 1.45-.49A6.974 6.974 0 0012 19c.74 0 1.45-.12 2.12-.34l1.45.49 1.17-1.17-.49-1.45A6.974 6.974 0 0019 12c0-.74-.12-1.45-.34-2.12l.49-1.45-1.17-1.17-1.45.49A6.974 6.974 0 0012 5zm0-2c-1.1 0-2 .9-2 2 0 .74.4 1.37 1 1.73V7h2V6.73c.6-.36 1-.99 1-1.73 0-1.1-.9-2-2-2z"
                                                    />
                                                </svg>
                                                <span>Settings</span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a
                                                href={"/logout"}
                                                className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-purple-500"
                                                onClick={handleClick}
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                    />
                                                </svg>
                                                <span>Logout</span>
                                            </a>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </div>
                        </Menu>
                    </div>
                ) : (
                    <div className="flex items-center space-x-2 md:space-x-3">
                        <Link
                            to="/login"
                            className="transition-all duration-300 px-2 md:px-3 lg:px-4 font-medium py-1 hover:text-indigo-600 text-sm md:text-base"
                        >
                            Login
                        </Link>
                        <Link
                            to="/register"
                            className="transition-all duration-300 px-2 md:px-3 lg:px-4 font-medium py-1 bg-indigo-600 text-white rounded-md focus:outline-none hover:bg-indigo-700 focus:ring focus:border-indigo-500 focus:ring-indigo-500/50 text-sm md:text-base"
                        >
                            Sign Up
                        </Link>
                    </div>
                )}

                {/* Mobile Menu Button - Only show if user is logged in or for navigation links */}
                {(checkUser() || true) && (
                    <div className="md:hidden">
                        <Menu as="div" className="relative inline-block text-left">
                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-indigo-600 p-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <HiMenu className="h-6 w-6" />
                            </Menu.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 mt-2 p-3 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {checkUser() ? (
                                        <div className="space-y-2">
                                            <div className="flex items-center space-x-3 p-2 border-b border-gray-100 mb-2">
                                                <FaUserCircle className="w-10 h-10 text-gray-600" />
                                                <div className="text-sm">
                                                    <p className="font-medium text-gray-900">
                                                        {profile
                                                            ? `${profile.first_name} ${profile.last_name}`
                                                            : "Loading..."}
                                                    </p>
                                                    <p className="text-gray-500">
                                                        {profile ? profile.email : "Loading..."}
                                                    </p>
                                                </div>
                                            </div>
                                            <Menu.Item>
                                                <Link
                                                    to="/dashboard"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Dashboard
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link
                                                    to="/remittance"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Send Money
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link
                                                    to="/transactions"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Transactions
                                                </Link>
                                            </Menu.Item>
                                            <hr className="my-2" />
                                            <Menu.Item>
                                                <Link
                                                    to="/profile"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Profile
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link
                                                    to="/settings"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Settings
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <a
                                                    href="/logout"
                                                    onClick={handleClick}
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Logout
                                                </a>
                                            </Menu.Item>
                                        </div>
                                    ) : (
                                        <div className="space-y-2">
                                            <Menu.Item>
                                                <Link
                                                    to="#how-it-works"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    How it works
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link
                                                    to="#help"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Help
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link
                                                    to="#contact"
                                                    className="block transition-all duration-300 font-medium py-2 hover:text-indigo-600"
                                                >
                                                    Contact Us
                                                </Link>
                                            </Menu.Item>
                                        </div>
                                    )}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                )}
            </Container>
        </nav>
    );
};

export default Nav;
