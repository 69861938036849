import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { FcExport } from "react-icons/fc";
import { Link } from "react-router-dom";

import DashboardCountrySelect from "../components/DashboardCountrySelect";
import DashboardNumberInput from "../components/DashboardNumberInput";
import { Container } from "../components/utils";
import config from "../config";
import { formatToCurrency } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Nav from "../layouts/Nav";
import {
    getProfile,
    getTransactions,
    getExchangeRates,
    getCountries,
    getCustomerActivities,
    getRecipients
} from "../services/apiService";

const getTimeAgo = dateString => {
    try {
        const date = new Date(dateString);
        const now = new Date();

        // Ensure valid date
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "Just now";
        }

        const seconds = Math.floor((now - date) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        // If date is in the future
        if (date > now) {
            console.log("Future date detected:", dateString);
            // Format the date to a readable string
            return date.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            });
        }

        if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        } else if (seconds > 10) {
            // Only show "seconds ago" if more than 10 seconds
            return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
        } else {
            return "Just now";
        }
    } catch (error) {
        console.error("Error calculating time ago:", error);
        return "Just now";
    }
};

const Dashboard = () => {
    const [profile, setProfile] = useState(null);
    const [recentTransaction, setRecentTransaction] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [selectedFromCountryOption, setSelectedFromCountryOption] = useState(null);
    const [selectedToCountryOption, setSelectedToCountryOption] = useState(null);
    const [exchangeRates, setExchangeRates] = useState(null);
    const [amountToSend, setAmountToSend] = useState("50");
    const [amountToReceive, setAmountToReceive] = useState("");
    const [currentExchangeRate, setCurrentExchangeRate] = useState("");
    const [debouncedAmountToSend, setDebouncedAmountToSend] = useState("");
    const [isCalculating, setIsCalculating] = useState(false);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const activitiesData = await getCustomerActivities();

                setActivities(activitiesData || []);

                try {
                    const [profileResponse, transactionsResponse] = await Promise.all([
                        getProfile(),
                        getTransactions()
                    ]);

                    setProfile(profileResponse.data);

                    if (transactionsResponse.data?.length > 0) {
                        const sortedTransactions = transactionsResponse.data.sort(
                            (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
                        );
                        setRecentTransaction(sortedTransactions[0]);
                    }
                } catch (otherError) {
                    console.error("Error fetching profile or transactions:", otherError);
                }
            } catch (error) {
                console.error("Error fetching activities:", error);
                setActivities([]);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const demo = document.querySelector("#scroll-sal-bar", {
            wheelSpeed: 0.5,
            swipeEasing: !0,
            minScrollbarLength: 40,
            maxScrollbarLength: 100,
            suppressScrollY: true
        });
        new PerfectScrollbar(demo);
    }, []);

    useEffect(() => {
        const fetchExchangeRates = async () => {
            try {
                const response = await getExchangeRates();
                let exchangeRate = [];
                for (const [key, value] of Object.entries(response.data)) {
                    exchangeRate.push({ key, value });
                }
                exchangeRate.push({ key: "USDUSD", value: 1 });
                setExchangeRates(exchangeRate);
            } catch (error) {
                console.error(`exchange rate: ${error}`);
            }
        };
        fetchExchangeRates();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedAmountToSend(amountToSend);
        }, 500);

        return () => clearTimeout(timer);
    }, [amountToSend]);

    useEffect(() => {
        const calculateAmount = async () => {
            if (
                selectedFromCountryOption &&
                selectedToCountryOption &&
                debouncedAmountToSend &&
                exchangeRates &&
                !isCalculating
            ) {
                setIsCalculating(true);
                try {
                    const amountInFromCurrency = parseFloat(
                        debouncedAmountToSend.replace(/,/g, "")
                    );
                    const fromCountryCurrencyCode = selectedFromCountryOption?.currency_code.trim();
                    const toCountryCurrencyCode = selectedToCountryOption?.currency_code.trim();

                    const fromExchangeRate = exchangeRates.find(
                        rate => rate.key === `USD${fromCountryCurrencyCode}`
                    );
                    const toExchangeRate = exchangeRates.find(
                        rate => rate.key === `USD${toCountryCurrencyCode}`
                    );

                    let convertAmountToSendToUsd = amountInFromCurrency / fromExchangeRate?.value;
                    let convertUsdToAmountToReceive =
                        convertAmountToSendToUsd * toExchangeRate?.value;

                    parseFloat(convertUsdToAmountToReceive).toFixed(2) < 0
                        ? setAmountToReceive("0")
                        : setAmountToReceive(parseFloat(convertUsdToAmountToReceive).toFixed(2));

                    setCurrentExchangeRate(
                        `1 ${selectedFromCountryOption?.currency_code} = ${parseFloat(
                            (1 / fromExchangeRate?.value) * toExchangeRate?.value
                        ).toFixed(2)} ${selectedToCountryOption?.currency_code}`
                    );
                } catch (error) {
                    console.error("Error calculating amount:", error);
                    setAmountToReceive("0.00");
                } finally {
                    setIsCalculating(false);
                }
            }
        };

        calculateAmount();
    }, [
        selectedFromCountryOption,
        selectedToCountryOption,
        debouncedAmountToSend,
        exchangeRates,
        isCalculating
    ]);

    useEffect(() => {
        const setDefaultCountries = async () => {
            try {
                const response = await getCountries();
                const countries = response.data;

                const usa = countries.find(
                    country =>
                        country.country_code === "US" ||
                        country.country_name.trim().toUpperCase() === "UNITED STATES"
                );
                const kenya = countries.find(
                    country =>
                        country.country_code === "KE" ||
                        country.country_name.trim().toUpperCase() === "KENYA"
                );

                if (usa) setSelectedFromCountryOption(usa);
                if (kenya) setSelectedToCountryOption(kenya);
            } catch (error) {
                console.error("Error setting default countries:", error);
            }
        };

        setDefaultCountries();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch both transactions and recipients
                const [transactionsResponse, recipientsResponse] = await Promise.all([
                    getTransactions(),
                    getRecipients()
                ]);

                setRecipients(recipientsResponse.data);

                if (transactionsResponse.data?.length > 0) {
                    const sortedTransactions = transactionsResponse.data.sort(
                        (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
                    );
                    setRecentTransaction(sortedTransactions[0]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const kycStatus = config.AUTH.DRIVER.getItem("kyc_status");
    const needsKYC = kycStatus === "0" || !kycStatus;

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    <div className="grid grid-cols-6 gap-8">
                        {needsKYC && (
                            <Link
                                to="/kyc-verification"
                                className="col-span-6 px-4 py-3 bg-white border rounded-xl shadow-xs lg:col-span-2 md:col-span-3 mb-4 hover:bg-gray-100 hover:border-gray-300 hover:shadow-lg hover:scale-105 transform transition duration-200"
                            >
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h3 className="text-sm">KYC Verification</h3>
                                        <p className="text-sm text-gray-600 p-2">
                                            <span className="text-blue-900 font-bold">
                                                Initiate KYC
                                            </span>
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 overflow-hidden">
                                        <i className="fas fa-receipt text-green-500 text-xl"></i>
                                    </div>
                                </div>
                            </Link>
                        )}

                        <Link
                            to="/recipients"
                            className={`block col-span-6 ${
                                needsKYC
                                    ? "lg:col-span-2 md:col-span-3"
                                    : "lg:col-span-3 md:col-span-3"
                            } mb-4 transition duration-200 transform hover:scale-105`}
                        >
                            <div className="h-full px-4 py-3 bg-white border rounded-xl shadow-xs hover:bg-gray-100 hover:border-gray-300 hover:shadow-lg transition duration-200">
                                <div className="flex items-center justify-between px-4">
                                    <div>
                                        <h3 className="text-lg">Recipients</h3>
                                    </div>
                                    <div className="flex items-center justify-center w-12 h-12 text-2xl rounded-full text-amber-500 bg-amber-100">
                                        <FaPeopleGroup className="w-6 h-6" />
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link
                            to="/remittance"
                            className={`block col-span-6 ${
                                needsKYC
                                    ? "lg:col-span-2 md:col-span-3"
                                    : "lg:col-span-3 md:col-span-3"
                            } mb-4 transition duration-200 transform hover:scale-105`}
                        >
                            <div className="h-full px-4 py-3 bg-white border rounded-xl shadow-xs hover:bg-gray-100 hover:border-gray-300 hover:shadow-lg transition duration-200">
                                <div className="flex items-center justify-between px-4">
                                    <div>
                                        <h3 className="text-lg">Send Money</h3>
                                    </div>
                                    <div className="flex items-center justify-center w-12 h-12 text-2xl rounded-full text-amber-500 bg-amber-100">
                                        <FcExport className="w-6 h-6" />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="grid grid-cols-4 gap-8 mt-8">
                        <div className="col-span-6 px-4 py-3 bg-white border rounded-md shadow-xs md:col-span-3 lg:col-span-2">
                            <h3 className="text-lg">Recent Activities</h3>

                            <div id="scroll-sal-bar" className="space-y-5 relative max-h-96">
                                {Array.isArray(activities) && activities.length > 0 ? (
                                    activities.map((activity, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center justify-between"
                                        >
                                            <div className="flex space-x-2">
                                                <div className="w-1.5 h-1.5 mt-2 text-blue-600 bg-blue-500 rounded-lg flex items-center justify-center" />
                                                <div>
                                                    <p className="text-sm text-gray-600">
                                                        {activity.activity}
                                                    </p>
                                                    <p className="text-xs text-gray-400">
                                                        {activity.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <p className="text-xs font-light text-gray-400">
                                                {getTimeAgo(activity.date)}
                                            </p>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-sm text-gray-500 text-center">
                                        No recent activities
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="col-span-6 overflow-hidden bg-white border shadow-xs rounded-t-2xl rounded-b-md md:col-span-3 lg:col-span-2">
                            <div className="py-4 text-white px-7 bg-gradient-to-r from-blue-500 to-blue-900 rounded-2xl">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center p-1 bg-gray-900 rounded-full bg-opacity-50 space-x-1.5">
                                        <FaUserCircle className="w-8 h-8 text-white" />
                                        <h4 className="pr-2 text-xs text-white text-opacity-700">
                                            {profile
                                                ? `${profile.first_name} ${profile.last_name}`
                                                : "User"}
                                        </h4>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="space-y-3">
                                        <div className="mb-4 w-full">
                                            <label
                                                className="block mb-2 font-semibold text-white"
                                                htmlFor="fromCountry"
                                            >
                                                From Country:
                                            </label>
                                            <DashboardCountrySelect
                                                id="fromCountry"
                                                selectedOption={selectedFromCountryOption}
                                                setSelectedOption={setSelectedFromCountryOption}
                                                necessary={true}
                                            />
                                        </div>

                                        <div className="mb-4 w-full">
                                            <label
                                                className="block mb-2 font-semibold text-white"
                                                htmlFor="toCountry"
                                            >
                                                To Country:
                                            </label>
                                            <DashboardCountrySelect
                                                id="toCountry"
                                                selectedOption={selectedToCountryOption}
                                                setSelectedOption={setSelectedToCountryOption}
                                                necessary={true}
                                            />
                                        </div>

                                        <div className="mb-4 w-full">
                                            <label
                                                className="block mb-2 font-semibold text-white"
                                                htmlFor="amount"
                                            >
                                                Amount:
                                            </label>
                                            <DashboardNumberInput
                                                formattedValue={amountToSend}
                                                setFormattedValue={setAmountToSend}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-5 mt-2 px-7">
                                <div className="grid grid-cols-2 gap-5">
                                    <div className="px-5 py-3 bg-white border rounded-md shadow-lg border-opacity-30">
                                        <div className="flex items-center space-x-3">
                                            <span className="text-sm font-medium text-gray-700">
                                                Amount To Receive
                                            </span>
                                        </div>
                                        <div className="bg-gray-50 rounded-md px-2 py-2 text-center mt-2.5 font-medium text-gray-700 border border-gray-200">
                                            {isCalculating ? (
                                                <span className="text-gray-400">
                                                    Calculating...
                                                </span>
                                            ) : amountToReceive && selectedToCountryOption ? (
                                                `${amountToReceive} ${selectedToCountryOption.currency_code}`
                                            ) : (
                                                "0.00"
                                            )}
                                        </div>
                                    </div>
                                    <div className="px-5 py-3 bg-white border rounded-md shadow-lg border-opacity-30">
                                        <div className="flex items-center space-x-3">
                                            <span className="text-sm font-medium text-gray-700">
                                                Rate Used
                                            </span>
                                        </div>
                                        <div className="bg-gray-50 rounded-md px-2 py-2 text-center mt-2.5 font-medium text-gray-700 border border-gray-200">
                                            {isCalculating ? (
                                                <span className="text-gray-400">
                                                    Calculating...
                                                </span>
                                            ) : (
                                                currentExchangeRate || "N/A"
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 pt-5 border-t border-gray-200">
                                    <h4 className="text-lg mb-3 font-medium text-gray-700">
                                        Last Transaction
                                    </h4>
                                    {recentTransaction && (
                                        <div className="space-y-2 text-gray-600">
                                            <p>
                                                Sent:{" "}
                                                {formatToCurrency(
                                                    recentTransaction.amount_sent,
                                                    recentTransaction.amount_sent_currency
                                                )}
                                            </p>
                                            <p>
                                                Received:{" "}
                                                {formatToCurrency(
                                                    recentTransaction.amount_received,
                                                    recentTransaction.amount_received_currency
                                                )}
                                            </p>
                                            <p>
                                                Recipient:{" "}
                                                <span className="font-medium">
                                                    {recipients.find(
                                                        recipient =>
                                                            recipient.recipient_id ===
                                                            recentTransaction.recipient_id
                                                    )
                                                        ? `${
                                                              recipients.find(
                                                                  recipient =>
                                                                      recipient.recipient_id ===
                                                                      recentTransaction.recipient_id
                                                              ).first_name
                                                          } ${
                                                              recipients.find(
                                                                  recipient =>
                                                                      recipient.recipient_id ===
                                                                      recentTransaction.recipient_id
                                                              ).last_name
                                                          }`
                                                        : "N/A"}
                                                </span>
                                            </p>
                                            <p>
                                                Sender's Country:{" "}
                                                <span className="font-medium">
                                                    {recentTransaction.from_country || "N/A"}
                                                </span>
                                            </p>
                                            <p>
                                                Recipient's Country:{" "}
                                                <span className="font-medium">
                                                    {recentTransaction.to_country || "N/A"}
                                                </span>
                                            </p>
                                            <p>
                                                Date:{" "}
                                                <span>
                                                    {new Date(
                                                        recentTransaction.time_stamp
                                                    ).toLocaleDateString("en-US", {
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit"
                                                    })}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </AdminLayout>
        </>
    );
};

export default Dashboard;
