import axios from "axios";

import config from "../config";

const getToken = () => config.AUTH.DRIVER.getItem("token");

const apiGeneralClient = axios.create({
    // baseURL: "http://127.0.0.1:5000",
    baseURL: "https://www.linksconnect.codefremics.com",
    headers: {
        "Content-Type": "application/json"
    }
});

// Add this new client for authentication
const apiAuthClient = axios.create({
    // baseURL: "http://127.0.0.1:5000",
    baseURL: "https://www.linksconnect.codefremics.com",
    headers: {
        "Content-Type": "application/json"
    }
});

apiGeneralClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
});

const apiFormDataClient = axios.create({
    // baseURL: "http://127.0.0.1:5000",
    baseURL: "https://www.linksconnect.codefremics.com",
    headers: {
        "Content-Type": "multipart/form-data"
    }
});

apiFormDataClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
});

export const userLogin = async (email, password) => {
    try {
        const response = await apiAuthClient.post("/api/v1/authentication/temp-login", {
            email,
            password
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
export const verify2FAOTP = async (otp, tempToken = null) => {
    try {
        const client = tempToken
            ? axios.create({
                  baseURL: apiGeneralClient.defaults.baseURL,
                  headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${tempToken}`
                  }
              })
            : apiGeneralClient;

        const response = await client.post("/api/v1/authentication/verify-otp", {
            otp,
            is_login: !!tempToken
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

export const deactivateAccount = async () => {
    try {
        const response = await apiGeneralClient.post(
            "/api/v1/authentication/deactivate-account",
            {}
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getProfile = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/customer/get-customer-details");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const updateProfile = async profile => {
    try {
        const response = await apiGeneralClient.put(
            "/api/v1/customer/update-customer-details",
            profile
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const userRegistration = async details => {
    try {
        const response = await apiGeneralClient.post("/api/v1/authentication/register", details);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const changePassword = async data => {
    try {
        const response = await apiGeneralClient.put("/api/v1/authentication/change-password", data);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const activateAccount = async code => {
    try {
        const encodedCode = encodeURIComponent(code);
        const response = await apiGeneralClient.post(
            `/api/v1/authentication/account/activate?code=${encodedCode}`
        );
        return response.data;
    } catch (error) {
        console.error("Error activating account:", error);
        throw error;
    }
};

export const reactivateAccount = async data => {
    try {
        const response = await apiGeneralClient.post(
            "/api/v1/authentication/reactivate-account",
            data
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getExchangeRates = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/rates/get-exchange-rates");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getExchangeRatesPublic = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/rates/get-exchange-rates-public");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getCountries = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/countries/get-countries-info");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getCountriesPublic = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/countries/get-countries-info-public");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getTransferFees = async () => {
    try {
        const response = await apiGeneralClient.get(
            "/api/v1/transfer-fees/get-current-transfer-fees"
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const saveTransaction = async remittance => {
    try {
        const response = await apiGeneralClient.post(
            "/api/v1/remittance/save-remittance",
            remittance
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getTransactions = async () => {
    try {
        const response = await apiGeneralClient.get(
            "/api/v1/remittance/get-user-remittance-records"
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getTransactionDetails = async remittance_id => {
    try {
        const response = await apiGeneralClient.get(
            `/api/v1/remittance/get-remittance-details/${remittance_id}`
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const addRecipient = async recipient => {
    try {
        const response = await apiGeneralClient.post(
            "/api/v1/recipients/add-new-recipient",
            recipient
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getRecipients = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/recipients/get-active-recipients");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getRecipientsDetails = async recipient_id => {
    try {
        const response = await apiGeneralClient.get(
            `/api/v1/recipients/fetch-recipient-details/${recipient_id}`
        );
        // console.log(response.data);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const deleteRecipient = async recipient_id => {
    try {
        const response = await apiGeneralClient.delete(
            `/api/v1/recipients/delete-recipient/${recipient_id}`
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const disableRecipient = async recipient_id => {
    try {
        const response = await apiGeneralClient.put(
            `/api/v1/recipients/disable-recipient/${recipient_id}`
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const updateRecipientDetails = async (recipient_id, recipient) => {
    try {
        const response = await apiGeneralClient.put(
            `/api/v1/recipients/update-recipient-details/${recipient_id}`,
            recipient
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const saveKYC = async kyc => {
    try {
        const response = await apiFormDataClient.post("/api/v1/kyc/save-kyc", kyc);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const createVeriffSession = async () => {
    try {
        const response = await apiGeneralClient.post("/api/v1/veriff/create-veriff-session");
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const UploadVeriffImages = async (sessionId, data) => {
    try {
        const response = await apiFormDataClient.post(
            `/api/v1/veriff/upload-veriff-images/${sessionId}`,
            data
        );
        // console.log(response.data);
        return response.data;
    } catch (error) {
        // console.log(error.response.data);
        throw error.response.data;
    }
};

export const calculateRemittance = async data => {
    try {
        const response = await apiGeneralClient.post(
            "/api/v1/transfer-fees/get-current-transfer-fees",
            data
        );
        return response.data;
    } catch (error) {
        console.error("API Error:", error.response);
        throw error.response ? error.response.data : error;
    }
};

export const getTransactionLimits = async currency_from => {
    try {
        const trimmedCurrency = currency_from.trim();
        console.log(
            "Sending request to get transaction limits with payload:",
            JSON.stringify({ currency_from: trimmedCurrency }, null, 2)
        );
        const response = await apiGeneralClient.post(
            "/api/v1/transaction-limits/fetch-current-transaction-limits",
            { currency_from: trimmedCurrency }
        );
        console.log("Received response for transaction limits:", response.data);
        return response.data;
    } catch (error) {
        console.error("API Error:", error.response);
        throw error.response ? error.response.data : error;
    }
};

export const getCustomerActivities = async () => {
    try {
        const response = await apiGeneralClient.get("/api/v1/customer/get-customer-activities");

        // Return the nested data array directly
        return response.data.data || []; // Ensure we always return an array
    } catch (error) {
        console.error("Error in getCustomerActivities:", error);
        return []; // Return empty array on error
    }
};

export const enable2FA = async () => {
    const response = await apiGeneralClient.post("/api/v1/authentication/enable-2fa");
    return response;
};

export const disable2FA = async () => {
    const response = await apiGeneralClient.post("/api/v1/authentication/disable-2fa");
    return response;
};
