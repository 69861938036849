import React from "react";

import NumberTextInput from "./NumberTextInput";

const DashboardNumberInput = ({ formattedValue, setFormattedValue }) => {
    return (
        <div className="dashboard-input relative">
            <NumberTextInput
                placeholder="Enter Amount"
                formattedValue={formattedValue}
                setFormattedValue={setFormattedValue}
            />
            <style>
                {`
                    .dashboard-input input {
                        width: 100% !important;
                        padding: 8px 0 !important;
                        background-color: transparent !important;
                        color: white !important;
                        font-weight: 600 !important;
                        border: none !important;
                        border-radius: 0 !important;
                    }
                    .dashboard-input {
                        border-bottom: 2px solid #CBD5E0 !important;
                    }
                    .dashboard-input input:focus {
                        outline: none !important;
                    }
                    .dashboard-input input::placeholder {
                        color: #E5E7EB !important;
                        font-weight: 500 !important;
                    }
                `}
            </style>
        </div>
    );
};

export default DashboardNumberInput;
