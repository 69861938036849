import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

import { Container } from "../components/utils";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { getRecipients } from "../services/apiService";

const ListRecipients = () => {
    const [recipients, setRecipients] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recipientsPerPage] = useState(10);

    useEffect(() => {
        const fetchRecipients = async () => {
            try {
                const response = await getRecipients();
                console.log(response.data);
                setRecipients(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchRecipients();
    }, []);

    const filteredRecipients = recipients.filter(recipient =>
        Object.values(recipient).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const indexOfLastRecipient = currentPage * recipientsPerPage;
    const indexOfFirstRecipient = indexOfLastRecipient - recipientsPerPage;
    const currentRecipients = filteredRecipients.slice(indexOfFirstRecipient, indexOfLastRecipient);
    const totalPages = Math.ceil(filteredRecipients.length / recipientsPerPage);

    const Pagination = () => (
        <div className="flex justify-center items-center space-x-2 mt-4">
            <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 rounded bg-gray-200 disabled:opacity-50"
            >
                Previous
            </button>
            <span className="px-3 py-1">
                Page {currentPage} of {totalPages}
            </span>
            <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 rounded bg-gray-200 disabled:opacity-50"
            >
                Next
            </button>
        </div>
    );

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    <div className="w-full md:w-3/12 mb-4 flex items-center justify-start">
                        <Link to={"/dashboard"}>
                            <h2 className="text-xl hover:text-blue-500 text-gray-500 flex flex-row items-center justify-start">
                                <ArrowBackIcon className="mr-2" />
                                Dashboard
                            </h2>
                        </Link>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <div className="w-full bg-white p-4 md:p-6 rounded-md shadow-md mb-8 md:mb-0">
                            <div className="flex flex-col md:flex-row justify-between items-center mb-8 mx-4 space-y-4 md:space-y-0">
                                <h1 className="text-2xl font-bold text-blue-700">Recipients</h1>
                                <div className="flex flex-col md:flex-row w-full md:w-auto space-y-4 md:space-y-0 md:space-x-4">
                                    <div className="relative flex-1 md:flex-initial">
                                        <input
                                            type="text"
                                            placeholder="Search recipients..."
                                            value={searchTerm}
                                            onChange={e => setSearchTerm(e.target.value)}
                                            className="w-full px-4 py-2 border rounded-md pr-10"
                                        />
                                        <FaSearch className="absolute right-3 top-3 text-gray-400" />
                                    </div>
                                    <Link
                                        to="/recipients/add"
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-center whitespace-nowrap"
                                    >
                                        Add Recipient
                                    </Link>
                                </div>
                            </div>
                            {currentRecipients.length > 0 ? (
                                <>
                                    {/* Desktop View */}
                                    <div className="hidden md:block">
                                        <table className="w-full">
                                            <thead>
                                                <tr className="text-left text-gray-500">
                                                    <th className="pb-4 w-1/6">First Name</th>
                                                    <th className="pb-4 w-1/6">Last Name</th>
                                                    <th className="pb-4 w-1/6">Mobile Number</th>
                                                    <th className="pb-4 w-1/6">Email</th>
                                                    <th className="pb-4 w-1/6">Country</th>
                                                    <th className="pb-4 w-1/6">Transaction Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentRecipients.map((recipient, index) => (
                                                    <tr
                                                        key={index}
                                                        className="border-t hover:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                                                    >
                                                        <td colSpan="6" className="p-0">
                                                            <Link
                                                                to={`/recipients/${recipient.recipient_id}`}
                                                                className="block w-full"
                                                            >
                                                                <div className="flex py-4">
                                                                    <div className="w-1/6">
                                                                        {recipient.first_name}
                                                                    </div>
                                                                    <div className="w-1/6">
                                                                        {recipient.last_name}
                                                                    </div>
                                                                    <div className="w-1/6">
                                                                        {recipient.mobile_number}
                                                                    </div>
                                                                    <div className="w-1/6">
                                                                        {recipient.email}
                                                                    </div>
                                                                    <div className="w-1/6">
                                                                        {recipient.country}
                                                                    </div>
                                                                    <div className="w-1/6">
                                                                        {recipient.transaction_type}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Mobile View */}
                                    <div className="md:hidden">
                                        {currentRecipients.map((recipient, index) => (
                                            <Link
                                                key={index}
                                                to={`/recipients/${recipient.recipient_id}`}
                                                className="block border-b last:border-b-0 hover:bg-gray-50 transition duration-150"
                                            >
                                                <div className="py-4">
                                                    <div className="flex justify-between items-center">
                                                        <div className="space-y-1">
                                                            <div className="font-medium">
                                                                {recipient.first_name}{" "}
                                                                {recipient.last_name}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {recipient.country}
                                                            </div>
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {recipient.transaction_type}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>

                                    <Pagination />
                                </>
                            ) : (
                                <div className="flex items-center justify-center text-gray-500">
                                    <p>
                                        No recipients found.{" "}
                                        <Link
                                            to={"/recipients/add"}
                                            className="text-blue-500 hover:text-blue-700"
                                        >
                                            Add a recipient
                                        </Link>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </AdminLayout>
        </>
    );
};

export default ListRecipients;
