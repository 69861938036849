import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { FaSearch, FaDownload } from "react-icons/fa";
import { FcBusinessman } from "react-icons/fc";
import { Link } from "react-router-dom";

import { Container } from "../components/utils";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { getTransactions, getRecipients } from "../services/apiService";

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState("all");
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await getTransactions();
                setTransactions(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        const fetchRecipients = async () => {
            try {
                const response = await getRecipients();
                setRecipients(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchTransactions();
        fetchRecipients();
    }, []);

    const filteredTransactions = transactions.filter(transaction => {
        const recipient = recipients.find(r => r.recipient_id === transaction.recipient_id);
        const searchString =
            `${recipient?.first_name} ${recipient?.last_name} ${transaction.remittance_id}`.toLowerCase();
        const matchesSearch = searchString.includes(searchTerm.toLowerCase());

        if (statusFilter === "all") return matchesSearch;
        const status = parseInt(transaction.status);
        return (
            matchesSearch &&
            ((statusFilter === "pending" && status === 1) ||
                (statusFilter === "completed" && status === 2) ||
                (statusFilter === "failed" && status === 0))
        );
    });

    const paginatedTransactions = filteredTransactions.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const pageCount = Math.ceil(filteredTransactions.length / itemsPerPage);

    const downloadCSV = () => {
        const headers = [
            "Date",
            "Recipient",
            "Transaction ID",
            "Amount Sent",
            "Amount Received",
            "Status"
        ];
        const data = filteredTransactions.map(transaction => {
            const recipient = recipients.find(r => r.recipient_id === transaction.recipient_id);
            const status =
                parseInt(transaction.status) === 1
                    ? "Pending"
                    : parseInt(transaction.status) === 2
                    ? "Completed"
                    : "Failed";
            return [
                formatTimestamp(transaction.time_stamp).date,
                `${recipient?.first_name} ${recipient?.last_name}`,
                transaction.remittance_id,
                `${transaction.amount_sent} ${transaction.amount_sent_currency}`,
                `${transaction.amount_received} ${transaction.amount_received_currency}`,
                status
            ];
        });

        const csvContent = [headers, ...data].map(row => row.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "transactions.csv";
        a.click();
    };

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    <div className="w-3/12 mb-4 flex items-center justify-start">
                        <Link to={"/dashboard"}>
                            <h2 className="text-xl hover:text-blue-500 text-gray-500 flex flex-row items-center justify-start">
                                <ArrowBackIcon className="mr-2" />
                                Dashboard
                            </h2>
                        </Link>
                    </div>
                    <div className="bg-gray-100 min-h-screen md:space-x-8">
                        <div className="w-full bg-white p-4 md:p-6 rounded-md shadow-md">
                            <div className="mb-6">
                                <h2 className="text-xl font-semibold text-blue-700 mb-6">
                                    Transactions
                                </h2>
                                <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                                    <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="Search transactions..."
                                                className="pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:border-blue-500"
                                                value={searchTerm}
                                                onChange={e => setSearchTerm(e.target.value)}
                                            />
                                            <FaSearch className="absolute left-3 top-3 text-gray-400" />
                                        </div>

                                        <select
                                            className="px-4 py-2 border rounded-full focus:outline-none focus:border-blue-500"
                                            value={statusFilter}
                                            onChange={e => setStatusFilter(e.target.value)}
                                        >
                                            <option value="all">All Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="completed">Completed</option>
                                            <option value="failed">Failed</option>
                                        </select>

                                        <button
                                            onClick={downloadCSV}
                                            className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600"
                                        >
                                            <FaDownload /> Export
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <table className="w-full">
                                <thead>
                                    <tr className="flex flex-row text-left text-gray-500">
                                        <th className="flex justify-center mx-4 md:mx-0 pb-4 w-1/4">
                                            Recipient
                                        </th>
                                        <th className="flex justify-center mx-4 md:mx-0 pb-4 w-1/6">
                                            Date
                                        </th>
                                        <th className="hidden md:flex justify-center pb-4 w-1/6">
                                            Transaction ID
                                        </th>
                                        <th className="hidden md:flex ml-2 justify-center pb-4 w-1/6">
                                            Amount Sent
                                        </th>
                                        <th className="hidden md:flex justify-center pb-4 w-1/6">
                                            Amount Received
                                        </th>
                                        <th className="flex justify-center mx-4 md:mx-0 pb-4 w-1/6">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedTransactions
                                        .map((transaction, index) => (
                                            <tr
                                                key={index}
                                                className="border-t hover:bg-gray-50 transition duration-150 ease-in-out"
                                            >
                                                <td colSpan="6" className="p-0">
                                                    <Link
                                                        to={`/transactions/${transaction.remittance_id}`}
                                                        className="block w-full h-full"
                                                    >
                                                        <div className="flex items-center py-4 px-4">
                                                            <div className="md:w-1/4 mx-4  md:mx-0 flex items-center">
                                                                <div className="hidden md:flex items-center justify-center w-10 h-10 bg-blue-400 rounded-full md:mr-3">
                                                                    <FcBusinessman />
                                                                </div>
                                                                <div>
                                                                    <div className="font-semibold">
                                                                        {`${
                                                                            recipients.find(
                                                                                recipient =>
                                                                                    recipient.recipient_id ===
                                                                                    transaction.recipient_id
                                                                            )?.first_name
                                                                        } ${
                                                                            recipients.find(
                                                                                recipient =>
                                                                                    recipient.recipient_id ===
                                                                                    transaction.recipient_id
                                                                            )?.last_name
                                                                        }`}
                                                                    </div>
                                                                    <div className="hidden md:block text-sm text-gray-500">
                                                                        {
                                                                            recipients.find(
                                                                                recipient =>
                                                                                    recipient.recipient_id ===
                                                                                    transaction.recipient_id
                                                                            )?.country
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col mx-4 md:mx-0 justify-center w-1/6">
                                                                <div className="flex justify-center">
                                                                    {
                                                                        formatTimestamp(
                                                                            transaction.time_stamp
                                                                        ).date
                                                                    }
                                                                </div>
                                                                <div className="hidden md:flex justify-center text-sm text-gray-500">
                                                                    At{" "}
                                                                    {
                                                                        formatTimestamp(
                                                                            transaction.time_stamp
                                                                        ).time
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="hidden md:flex justify-center w-1/6">
                                                                {transaction.remittance_id}
                                                            </div>
                                                            <div className="hidden md:block ml-2 justify-center w-1/6 font-semibold">
                                                                {`${transaction.amount_sent} ${transaction.amount_sent_currency}`}
                                                            </div>
                                                            <div className="hidden md:block justify-center w-1/6 font-semibold">
                                                                {`${transaction.amount_received} ${transaction.amount_received_currency}`}
                                                            </div>
                                                            <div className="flex justify-center mx-4 md:mx-0 md:1/6">
                                                                <span
                                                                    className={`px-1 py-1 rounded-xl md:rounded-full text-xs md:text-sm ${
                                                                        parseInt(
                                                                            transaction.status
                                                                        ) === 1
                                                                            ? "bg-orange-100 text-orange-800"
                                                                            : parseInt(
                                                                                  transaction.status
                                                                              ) === 2
                                                                            ? "bg-green-100 text-green-800"
                                                                            : parseInt(
                                                                                  transaction.status
                                                                              ) === 0
                                                                            ? "bg-red-100 text-red-800"
                                                                            : "bg-gray-100 text-gray-800"
                                                                    }`}
                                                                >
                                                                    {parseInt(
                                                                        transaction.status
                                                                    ) === 1
                                                                        ? "In Progress"
                                                                        : parseInt(
                                                                              transaction.status
                                                                          ) === 2
                                                                        ? "Received"
                                                                        : parseInt(
                                                                              transaction.status
                                                                          ) === 0
                                                                        ? "Failed"
                                                                        : "Unknown"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                        .reverse()}
                                </tbody>
                            </table>

                            {/* Add pagination controls here, inside the white container */}
                            <div className="mt-4 flex justify-center gap-2">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50"
                                >
                                    Previous
                                </button>

                                {Array.from({ length: Math.max(1, pageCount) }, (_, i) => (
                                    <button
                                        key={i + 1}
                                        onClick={() => setCurrentPage(i + 1)}
                                        className={`px-3 py-1 rounded ${
                                            currentPage === i + 1
                                                ? "bg-blue-500 text-white"
                                                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                        }`}
                                    >
                                        {i + 1}
                                    </button>
                                ))}

                                <button
                                    onClick={() =>
                                        setCurrentPage(prev =>
                                            Math.min(prev + 1, Math.max(1, pageCount))
                                        )
                                    }
                                    disabled={currentPage === Math.max(1, pageCount)}
                                    className="px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </AdminLayout>
        </>
    );
};

export default Transactions;

function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);

    const getOrdinalSuffix = day => {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const dayOfMonth = dateObj.getDate();
    const ordinalSuffix = getOrdinalSuffix(dayOfMonth);
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    // Format the date string
    const formattedDate = `${dayOfMonth}${ordinalSuffix} ${month} ${year}`;

    // Get hour, minute, and period (am/pm)
    let hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    let period = "am";

    // Convert hour to 12-hour format and determine period (am/pm)
    if (hour >= 12) {
        period = "pm";
        if (hour > 12) hour -= 12; // Convert from 24-hour to 12-hour format
    }
    if (hour === 0) hour = 12; // Midnight should be 12am

    // Format the time string
    const formattedTime = `${hour}:${minute.toString().padStart(2, "0")}${period}`;

    return { date: formattedDate, time: formattedTime };
}
