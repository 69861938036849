import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";

import DocumentUpload from "../components/DocumentUpload";
import { Container } from "../components/utils";
import { toast } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { saveKYC } from "../services/apiService";

const KnowYourCustomer = () => {
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const webcamRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [selectedDocument, setSelectedDocument] = useState(null);
    const navigate = useNavigate();

    const turnOnCamera = () => setIsCameraOn(true);

    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImage(imageSrc);
        setIsPreviewMode(true);
    }, [webcamRef]);

    const retakePhoto = () => {
        setCapturedImage(null);
        setIsPreviewMode(false);
    };

    const acceptPhoto = () => {
        setIsCameraOn(false);
        setIsPreviewMode(false);
    };

    const deletePhoto = () => {
        setCapturedImage(null);
        setIsCameraOn(false);
        setIsPreviewMode(false);
    };

    const handleSubmit = async () => {
        setSubmitError("");

        if (!capturedImage) {
            setSubmitError("Please capture a photo of yourself.");
            return;
        }

        if (!selectedDocument || !selectedDocument.files.front) {
            setSubmitError("Please upload a document.");
            return;
        }

        setIsSubmitting(true);

        try {
            const formData = new FormData();

            // Append live photo
            formData.append("live_photo", dataURItoBlob(capturedImage), "user_photo.jpg");

            // Append selected document
            formData.append(
                "document_front",
                selectedDocument.files.front,
                selectedDocument.files.front.name
            );

            formData.append("document_type", selectedDocument?.type);

            if (selectedDocument.files.back) {
                formData.append(
                    "document_back",
                    selectedDocument.files.back,
                    selectedDocument.files.back.name
                );
            }

            console.log("Submitting KYC with data:", {
                live_photo: "user_photo.jpg",
                document_front: selectedDocument.files.front.name,
                document_type: selectedDocument?.type,
                document_back: selectedDocument.files.back?.name
            });

            const response = await saveKYC(formData);
            console.log("KYC submission response:", response);

            if (response.status === 200) {
                toast("success", "KYC documents submitted successfully");
                navigate("/dashboard");
            } else if (response.status === 409) {
                toast("error", response.message || "KYC documents already submitted");
            } else {
                toast("error", response.message || "Failed to submit KYC documents");
            }
        } catch (error) {
            console.error("KYC submission error:", error);
            toast("error", error.description || "An error occurred while submitting KYC documents");
        } finally {
            setIsSubmitting(false);
        }
    };

    const dataURItoBlob = dataURI => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    return (
        <AdminLayout>
            <Nav />
            <Container>
                <div>
                    <Link to={"/dashboard"}>
                        <h2 className="text-xl hover:text-blue-500 text-gray-500 flex mb-4 flex-row items-center justify-start">
                            <ArrowBackIcon className="mr-2" />
                            Dashboard
                        </h2>
                    </Link>
                    <div className="container bg-white rounded shadow-md px-8 pt-6 pb-8 mb-4">
                        <h1 className="text-xl text-blue-700 font-semibold mb-8">
                            KYC Verification
                        </h1>

                        <h3 className="text-xl font-normal mb-2">A photo of you</h3>

                        <div className="mb-4 flex items-center justify-center">
                            {!isCameraOn && !capturedImage && (
                                <div
                                    onClick={turnOnCamera}
                                    className="w-full h-48 flex items-center justify-center bg-gray-200 cursor-pointer rounded-lg hover:bg-gray-300 transition-colors duration-300"
                                >
                                    <CameraAltIcon style={{ fontSize: 48, color: "#4B5563" }} />
                                    <span className="ml-2 text-gray-600">
                                        Click to take a photo
                                    </span>
                                </div>
                            )}

                            {isCameraOn && !isPreviewMode && (
                                <div className="w-1/2 h-3/4 mb-4 flex flex-col items-center justify-center relative">
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        className="w-full mb-2 rounded-md"
                                    />
                                    <button
                                        onClick={capturePhoto}
                                        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-800 rounded-full p-2 transition-all duration-300"
                                        aria-label="Capture Photo"
                                    >
                                        <CameraAltIcon style={{ fontSize: 36 }} />
                                    </button>
                                </div>
                            )}

                            {isPreviewMode && (
                                <div className="mb-4">
                                    <img
                                        src={capturedImage}
                                        alt="Captured"
                                        className="w-full mb-2"
                                    />
                                    <div className="flex justify-center space-x-2">
                                        <button
                                            onClick={acceptPhoto}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Accept
                                        </button>
                                        <button
                                            onClick={retakePhoto}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Retake
                                        </button>
                                    </div>
                                </div>
                            )}

                            {capturedImage && !isPreviewMode && (
                                <div className="relative">
                                    <img
                                        src={capturedImage}
                                        alt="Captured"
                                        className="w-full mb-4"
                                    />
                                    <button
                                        onClick={deletePhoto}
                                        className="absolute top-2 right-2 bg-gray-500 hover:bg-red-700 text-white rounded-full p-2"
                                        aria-label="Delete Photo"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </div>
                            )}
                        </div>

                        <DocumentUpload
                            selectedDocument={selectedDocument}
                            setSelectedDocument={setSelectedDocument}
                        />

                        <div className="mt-8">
                            {submitError && (
                                <p className="text-red-500 text-center text-sm mb-2">
                                    {submitError}
                                </p>
                            )}
                            <button
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                className={`w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg text-lg ${
                                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            >
                                {isSubmitting ? "Submitting..." : "Submit for Verification"}
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </AdminLayout>
    );
};

export default KnowYourCustomer;
