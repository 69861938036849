import React, { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "../components/buttons";
import { Input } from "../components/field";
import { Link, Loader } from "../components/utils";
import config from "../config";
import { toast } from "../helpers";
import AuthLayout from "../layouts/AuthLayout";
import { userLogin } from "../services/apiService";

const Login = () => {
    const navigate = useNavigate();
    // const captchaRef = useRef(null);
    const defaultMessage = {
        email: [],
        password: []
        // captcha: []
    };

    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState(defaultMessage);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const validateFields = () => {
        const newErrorMessage = { ...defaultMessage };
        let isValid = true;

        if (!email) {
            newErrorMessage.email = ["Email address is required"];
            isValid = false;
        }

        if (!password) {
            newErrorMessage.password = ["Password is required"];
            isValid = false;
        }

        // Commented out ReCAPTCHA validation
        // if (!captchaRef.current?.getValue()) {
        //     newErrorMessage.captcha = ["Please complete the captcha"];
        //     isValid = false;
        // }

        setErrorMessage(newErrorMessage);
        return isValid;
    };

    const login = async () => {
        if (!validateFields()) {
            return;
        }
        setLoading(true);

        // Commented out ReCAPTCHA reset
        // captchaRef.current.reset();

        try {
            const user = await userLogin(email, password);
            console.log(user);

            if (user.status === 200) {
                setInvalid(false);
                toast("success", user.description);
                if (user.data.verification_status === 1) {
                    config.AUTH.DRIVER.setItem("user", {
                        name: `${user.data.first_name} ${user.data.last_name}`,
                        permissions: ["reload-account", "dashboard", "transfer-money"]
                    });
                    config.AUTH.DRIVER.setItem("token", user.data.access_token);
                    config.AUTH.DRIVER.setItem("kyc_status", user.data.kyc_status);
                    navigate("/dashboard");
                } else if (user.data.verification_status === 2) {
                    navigate("/reactivate-account");
                }
            } else {
                setInvalid(true);
                toast("error", "An error occurred. Please try again.");
            }
        } catch (error) {
            setInvalid(true);
            toast("error", "Connection failed");
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout
            title={
                <>
                    Welcome back! <br />
                </>
            }
        >
            <h3 className="text-center text-xl font-semibold text-gray-700">Login to Account</h3>
            <p className="text-center text-sm mt-2 mb-10">
                Please sign-in to your account to proceed.
            </p>

            {invalid && (
                <div className="my-2 text-center text-red-600 bg-red-100 py-2 rounded-md">
                    Invalid email or password
                </div>
            )}

            <form className="space-y-6">
                <div>
                    <Input
                        label={"Email Address"}
                        id="email"
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={errorMessage.email}
                    />
                </div>

                <div>
                    <Input
                        label={"Password"}
                        id="password"
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        error={errorMessage.password}
                    />
                </div>

                {/* Commented out ReCAPTCHA component */}
                {/* {email && password && (
                    <ReCAPTCHA
                        className="flex items-center justify-center"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        ref={captchaRef}
                    />
                )}

                {email && password && (
                    <div className="flex items-center justify-center text-red-500">
                        {errorMessage.captcha}
                    </div>
                )} */}

                <div className="flex items-center justify-end">
                    {/* <Checkbox id="remember" label="Remember Me" /> */}

                    <Link href="/forgot-password">Forgot Password?</Link>
                </div>

                <PrimaryButton onClick={login} disabled={loading}>
                    {loading && <Loader color={"white"} />}
                    <span>Login to account</span>
                </PrimaryButton>

                <div className="flex items-center justify-center space-x-3">
                    <hr className="w-12" />
                    <span className="font-bold uppercase text-xs text-gray-400">Or</span>
                    <hr className="w-12" />
                </div>
                <p className="text-sm text-center">
                    Don't have an account? <Link href="/register">Register</Link>
                </p>
            </form>
        </AuthLayout>
    );
};

export default Login;
