import React, { useState, useEffect } from "react";
import { FaUser, FaEnvelope, FaGlobe, FaPhone } from "react-icons/fa";

import { Container } from "../components/utils";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { getProfile } from "../services/apiService";

const Profile = () => {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getProfile();
                setProfile(response["data"]);
            } catch (error) {
                console.error(error);
            }
        };
        fetchProfile();
    }, []);

    const ProfileItem = ({ icon: Icon, label, value }) => (
        <div className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg">
            <div className="mt-1">
                <Icon className="h-5 w-5 text-gray-400" />
            </div>
            <div className="flex-1">
                <p className="text-sm font-medium text-gray-500">{label}</p>
                <p className="mt-1 text-base text-gray-900">{value || "-"}</p>
            </div>
        </div>
    );

    const fullName = [profile?.first_name, profile?.middle_name, profile?.last_name]
        .filter(Boolean)
        .join(" ");

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    <div className="min-h-screen bg-gray-100 py-8">
                        <div className="max-w-7xl mx-auto">
                            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                                {/* Header */}
                                <div className="px-8 py-6 border-b border-gray-200 bg-white">
                                    <div className="space-y-1">
                                        <h2 className="text-2xl font-bold text-gray-900">
                                            Profile Information
                                        </h2>
                                        <p className="text-sm text-gray-500">
                                            Your personal information and contact details
                                        </p>
                                    </div>
                                </div>

                                {/* Content */}
                                <div className="px-8 py-6">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <ProfileItem
                                            icon={FaUser}
                                            label="Full Name"
                                            value={fullName}
                                        />
                                        <ProfileItem
                                            icon={FaEnvelope}
                                            label="Email Address"
                                            value={profile?.email}
                                        />
                                        <ProfileItem
                                            icon={FaGlobe}
                                            label="Country"
                                            value={profile?.country}
                                        />
                                        <ProfileItem
                                            icon={FaPhone}
                                            label="Mobile Number"
                                            value={profile?.mobile_number}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </AdminLayout>
        </>
    );
};

export default Profile;
