import React, { useState, useRef, useEffect } from "react";

import { getRecipients } from "../services/apiService";

const CustomSelectRecipient = ({ selectedOption, setSelectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    // const recipients = [
    //     { name: "John Doe" },
    //     { name: "Jane Smith" },
    //     { name: "Bob Johnson" },
    //     { name: "Alice Williams" },
    //     { name: "Tom Davis" },
    //     { name: "Sarah Lee" }
    //     // Add more recipients as needed
    // ];

    useEffect(() => {
        const fetchRecipients = async () => {
            try {
                const response = await getRecipients({ status: 1 });
                setRecipients(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchRecipients();
    }, []);

    useEffect(() => {
        const handleClickOutside = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                if (!selectedOption) {
                    setSearchTerm("");
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectedOption]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            inputRef.current.focus();
        }
    };

    const handleOptionClick = option => {
        setSelectedOption(option);
        setSearchTerm(option.first_name + " " + option.last_name);
        setIsOpen(false);
    };

    const handleInputChange = e => {
        setSearchTerm(e.target.value);
        setSelectedOption(null);
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const filteredRecipients = recipients?.filter(
        recipient =>
            recipient?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            recipient?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div className="flex">
                <input
                    ref={inputRef}
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={toggleDropdown}
                    placeholder="Select Recipient"
                    required
                />
            </div>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    <div className="max-h-60 overflow-y-auto">
                        {filteredRecipients.map(recipient => (
                            <div
                                key={recipient.recipient_id}
                                className="p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleOptionClick(recipient)}
                            >
                                {recipient.first_name} {recipient.last_name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelectRecipient;
