import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Auth, Guest } from "./middlewares";
import AccountActivation from "./pages/AccountActivation";
// import Activate from "./pages/Activate";
import AddRecipient from "./pages/AddRecipient";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import KnowYourCustomer from "./pages/KnowYourCustomer";
import ListRecipients from "./pages/ListRecipients";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import AccountReactivation from "./pages/ReactivateAccount";
import Recipient from "./pages/Recipient";
import Register from "./pages/Register";
import ReloadAccount from "./pages/ReloadAccount";
import Remittance from "./pages/Remittance";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import TransactionDetails from "./pages/TransactionDetails";
import Transactions from "./pages/Transactions";
import VerificationPending from "./pages/VerificationPending";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/login"
                    element={
                        <Guest>
                            <Login />
                        </Guest>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <Guest>
                            <Register />
                        </Guest>
                    }
                />
                {/* <Route
                    path="/activate-account"
                    element={
                        <Guest>
                            <Activate />
                        </Guest>
                    }
                /> */}
                <Route
                    path="/activate-account/activate"
                    element={
                        <Guest>
                            <AccountActivation />
                        </Guest>
                    }
                />
                <Route
                    path="/forgot-password"
                    element={
                        <Guest>
                            <ForgotPassword />
                        </Guest>
                    }
                />
                <Route
                    path="/reset-password"
                    element={
                        <Guest>
                            <ResetPassword />
                        </Guest>
                    }
                />
                <Route
                    path="/reactivate-account"
                    element={
                        <Guest>
                            <AccountReactivation />
                        </Guest>
                    }
                />

                <Route
                    path="/profile"
                    element={
                        <Auth>
                            <Profile />
                        </Auth>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <Auth>
                            <Settings />
                        </Auth>
                    }
                />
                <Route path="/verification-pending" element={<VerificationPending />} />

                <Route
                    path="/dashboard"
                    element={
                        <Auth>
                            <Dashboard />
                        </Auth>
                    }
                />
                <Route
                    path="/reload-account"
                    element={
                        <Auth>
                            <ReloadAccount />
                        </Auth>
                    }
                />
                <Route
                    path="/recipients/add"
                    element={
                        <Auth>
                            <AddRecipient />
                        </Auth>
                    }
                />
                <Route
                    path="/recipients"
                    element={
                        <Auth>
                            <ListRecipients />
                        </Auth>
                    }
                />
                <Route
                    path="/recipients/:recipient_id"
                    element={
                        <Auth>
                            <Recipient />
                        </Auth>
                    }
                />
                <Route
                    path="/remittance"
                    element={
                        <Auth>
                            <Remittance />
                        </Auth>
                    }
                />
                <Route path="/*" element={<Home />} />
                <Route
                    path="/transactions"
                    element={
                        <Auth>
                            <Transactions />
                        </Auth>
                    }
                />
                <Route
                    path="/transactions/:remittance_id"
                    element={
                        <Auth>
                            <TransactionDetails />
                        </Auth>
                    }
                />
                <Route
                    path="/kyc-verification"
                    element={
                        <Auth>
                            <KnowYourCustomer />
                        </Auth>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
