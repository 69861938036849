import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import React, { useState, useEffect } from "react";

const documentTypes = [
    { value: "id", label: "National ID" },
    { value: "passport", label: "Passport" },
    { value: "license", label: "Driver's License" },
    { value: "other", label: "Other" }
];

const CustomSelect = ({ options, value, onChange, disabled }) => (
    <select
        value={value}
        onChange={onChange}
        className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        disabled={disabled}
    >
        <option value="">Select document type</option>
        {options.map(option => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
            </option>
        ))}
    </select>
);

const DocumentUpload = ({ selectedDocument, setSelectedDocument }) => {
    const [documentType, setDocumentType] = useState(selectedDocument?.type || "");

    useEffect(() => {
        return () => {
            if (selectedDocument?.files.front) {
                URL.revokeObjectURL(URL.createObjectURL(selectedDocument.files.front));
            }
            if (selectedDocument?.files.back) {
                URL.revokeObjectURL(URL.createObjectURL(selectedDocument.files.back));
            }
        };
    }, [selectedDocument]);

    const handleTypeChange = event => {
        const type = event.target.value;
        setDocumentType(type);
        setSelectedDocument({
            type: type,
            files: { front: null, back: null }
        });
    };

    const handleFileUpload = event => {
        const file = event.target.files[0];
        if (file) {
            setSelectedDocument(prev => ({
                ...prev,
                files: {
                    ...prev.files,
                    [event.target.name]: file
                }
            }));
        }
    };

    const deleteDocument = () => {
        setSelectedDocument(null);
        setDocumentType("");
    };

    return (
        <div className="mb-4">
            <h3 className="text-xl font-normal mb-2">Upload your identification document</h3>
            <p className="text-sm text-gray-600 mb-2">
                Please upload government-issued documents only. For National ID, Green Card, or
                Driver's License, provide both front and back images.
            </p>
            <CustomSelect
                options={documentTypes}
                value={documentType}
                onChange={handleTypeChange}
                disabled={!!selectedDocument?.files.front}
            />
            {documentType === "other" && (
                <input
                    type="text"
                    name="other"
                    placeholder="Specify document type"
                    className="block w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            )}
            {documentType && (
                <div className="mt-2 space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Front side of{" "}
                            {documentType === "id"
                                ? "National ID"
                                : documentType === "passport"
                                ? "Passport"
                                : documentType === "license"
                                ? "Driver's License"
                                : "Document"}
                        </label>
                        <input
                            type="file"
                            name="front"
                            onChange={handleFileUpload}
                            accept="image/*"
                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        />
                    </div>

                    {(documentType === "id" ||
                        documentType === "license" ||
                        documentType === "other") && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Back side of{" "}
                                {documentType === "id"
                                    ? "National ID"
                                    : documentType === "license"
                                    ? "Driver's License"
                                    : "Document"}
                            </label>
                            <input
                                type="file"
                                name="back"
                                onChange={handleFileUpload}
                                accept="image/*"
                                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                            />
                        </div>
                    )}
                </div>
            )}
            {selectedDocument?.files.front && (
                <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Front side preview:</h4>
                    <div className="flex items-start space-x-4">
                        <div className="flex-1 bg-gray-100 p-2 rounded-lg">
                            <img
                                src={URL.createObjectURL(selectedDocument.files.front)}
                                alt="Front Document Preview"
                                className="max-h-40 object-contain mx-auto"
                            />
                            <div className="mt-2 flex items-center justify-between">
                                <div className="flex items-center">
                                    <InsertDriveFileIcon className="text-blue-500 mr-2" />
                                    <span className="text-gray-800 font-medium">
                                        {selectedDocument.files.front.name}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <span className="text-gray-500 text-sm mr-2">
                                        {(selectedDocument.files.front.size / 1024).toFixed(2)} KB
                                    </span>
                                    <button
                                        onClick={deleteDocument}
                                        className="text-red-500 hover:text-red-700"
                                        aria-label="Delete Document"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedDocument?.files.back && (
                <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Back side preview:</h4>
                    <div className="flex items-start space-x-4">
                        <div className="flex-1 bg-gray-100 p-2 rounded-lg">
                            <img
                                src={URL.createObjectURL(selectedDocument.files.back)}
                                alt="Back Document Preview"
                                className="max-h-40 object-contain mx-auto"
                            />
                            <div className="mt-2 flex items-center justify-between">
                                <div className="flex items-center">
                                    <InsertDriveFileIcon className="text-blue-500 mr-2" />
                                    <span className="text-gray-800 font-medium">
                                        {selectedDocument.files.back.name}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <span className="text-gray-500 text-sm mr-2">
                                        {(selectedDocument.files.back.size / 1024).toFixed(2)} KB
                                    </span>
                                    <button
                                        onClick={deleteDocument}
                                        className="text-red-500 hover:text-red-700"
                                        aria-label="Delete Document"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocumentUpload;
