import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { FcBusinessman } from "react-icons/fc";
import { Link, useNavigate, useLocation } from "react-router-dom";

import CustomSelectCountry from "../components/CustomSelectCountry";
import { Container } from "../components/utils";
import { toast } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { getCountries, addRecipient } from "../services/apiService";

const AddRecipient = () => {
    const location = useLocation();
    const { returnTo } = location.state || {};
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedValue, setSelectedValue] = useState("Bank Transfer");
    const [selectedCountry, setSelectedCountry] = useState();
    const [mobileNumberValue, setMobileNumberValue] = useState("");
    const [confirmMobileNumberValue, setConfirmMobileNumberValue] = useState("");
    const [accountNumberValue, setAccountNumberValue] = useState("");
    const [selectedCountryName, setSelectedCountryName] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    const [selectedBankBranch, setSelectedBankBranch] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    // const [selectedMobileOperator, setSelectedMobileOperator] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const data = await getCountries();
                setCountries(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        setSelectedCountryName(selectedCountry?.country_name);
    }, [selectedCountry, selectedCountryName]);

    useEffect(() => {
        setSelectedCountryName(selectedCountry?.country_name.trim());
    }, [selectedValue, selectedCountryName, selectedCountry]);

    const validateForm = () => {
        let errors = {};

        if (!selectedCountry) errors.country = "Country is required, ";
        if (!selectedValue) errors.transactionType = "Transaction type is required, ";
        if (!firstName.trim()) errors.firstName = "First name is required, ";
        if (!lastName.trim()) errors.lastName = "Last name is required, ";
        if (!emailValue.trim()) errors.email = "Email is required, ";
        else if (!/\S+@\S+\.\S+/.test(emailValue)) errors.email = "Email is invalid, ";
        if (!mobileNumberValue.trim()) errors.mobileNumber = "Mobile number is required, ";
        if (!confirmMobileNumberValue.trim())
            errors.confirmMobileNumber = "Confirm mobile number is required, ";
        if (mobileNumberValue !== confirmMobileNumberValue)
            errors.confirmMobileNumber = "Mobile numbers do not match, ";

        if (
            selectedValue === "Bank Transfer" &&
            selectedCountryName !== "United States of America"
        ) {
            if (!selectedBank) errors.bank = "Bank name is required, ";
            if (!selectedBankBranch) errors.bankBranch = "Bank branch is required, ";
            if (!accountNumberValue.trim()) errors.accountNumber = "Account number is required, ";
        }
        if (
            selectedValue === "Bank Transfer" &&
            selectedCountryName === "United States of America"
        ) {
            if (!selectedBank) errors.bank = "Bank name is required, ";
            if (!routingNumber) errors.routingNumber = "Routing Number is required, ";
            if (!accountNumberValue.trim()) errors.accountNumber = "Account number is required, ";
        }

        // if (selectedValue === "Mobile Transfer") {
        //     if (!selectedMobileOperator) errors.mobileOperator = "Mobile operator is required, ";
        // }

        return errors;
    };

    const handleNumberChange = (event, setValue) => {
        const newValue = event.target.value;
        if (/^\d*$/.test(newValue)) {
            setValue(newValue);
        }
    };

    // const handlePaste = event => {
    //     event.preventDefault();
    //     const text = event.clipboardData.getData("text/plain");
    // };

    const handleSubmit = async () => {
        const errorsOnForm = validateForm();
        if (Object.keys(errorsOnForm).length > 0) {
            let errorMessage = [];
            for (let key in errorsOnForm) {
                errorMessage.push(errorsOnForm[key]);
            }
            setErrorMessage(errorMessage);
            return;
        }

        setErrorMessage(null);
        const recipient = {
            country: selectedCountryName,
            transaction_type: selectedValue,
            first_name: firstName,
            last_name: lastName,
            email: emailValue,
            mobile_number: `+${selectedCountry.mobile_code}${mobileNumberValue}`,
            bank: selectedBank,
            bank_branch: selectedBankBranch,
            bank_account_number: accountNumberValue,
            // mobile_network: selectedMobileOperator,
            routing_number: routingNumber
            // street_address: address1,
            // postal_address: address2,
            // state: state,
            // city: city,
            // zip_code: zipCode
        };

        try {
            const response = await addRecipient(recipient);
            if (response.status == 200) {
                toast("success", response.description);
                returnTo === true ? navigate("/remittance") : navigate("/dashboard");
            } else if (response.status == 400) {
                setErrorMessage(response.description);
            }
        } catch (error) {
            if (error.description) {
                var errors = [];
                for (var description in error.description) {
                    errors.push(description);
                    setErrorMessage(errors.join(", "));
                }
            } else {
                setErrorMessage("An unexpected error occurred");
            }
        }
    };

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    <div className="md:w-3/12 mb-4 flex items-center justify-start">
                        {returnTo !== true && (
                            <Link to={"/recipients"}>
                                <h2 className="text-xl hover:text-blue-500 text-gray-500 flex flex-row items-center justify-start">
                                    <ArrowBackIcon className="mr-2" />
                                    Recipients
                                </h2>
                            </Link>
                        )}
                    </div>
                    <div className="p-8 bg-white rounded-md shadow-md">
                        <div className="flex flex-row justify-start mb-4 items-center h-full">
                            <div className="flex justify-center items-center w-8 h-8 rounded-full bg-blue-500 mr-2">
                                <FcBusinessman className="w-6 h-6" />
                            </div>

                            <h2 className="relative bottom-0 flex justify-center items-center h-8 text-xl font-semibold text-gray-700">
                                Add Recipient
                            </h2>
                        </div>

                        <form onSubmit={e => e.preventDefault()}>
                            <div className="w-full mb-4">
                                <label
                                    className="block mb-2 font-semibold text-gray-700"
                                    htmlFor="country"
                                >
                                    Country*
                                </label>
                                <CustomSelectCountry
                                    selectedOption={selectedCountry}
                                    setSelectedOption={setSelectedCountry}
                                    items={countries}
                                    necessary={false}
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block mb-2 font-semibold text-gray-700"
                                    htmlFor="transactionType"
                                >
                                    Transaction Type*
                                </label>
                                <select
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    id="transactionType"
                                    onChange={event => setSelectedValue(event.target.value)}
                                    value={selectedValue}
                                    required
                                >
                                    <option>Bank Transfer</option>
                                    <option>Mobile Transfer</option>
                                    <option>Cash Pickup</option>
                                </select>
                            </div>

                            <div className="flex md:flex-row flex-col md:justify-between">
                                <div className="mb-4 w-full md:w-6/12 md:mr-2">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="firstName"
                                    >
                                        First Name*
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="First Name"
                                        id="firstName"
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="block mb-4 flex-row items-center md:ml-2 md:w-6/12 border-gray-300">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="lastName"
                                    >
                                        Last Name*
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Last Name"
                                        id="lastName"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row justify-between">
                                <div className=" w-full mb-4 md:w-4/12 md:mr-2">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="email"
                                    >
                                        Email Address*
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Enter Email Address"
                                        value={emailValue}
                                        onChange={e => setEmailValue(e.target.value)}
                                        id="email"
                                        required
                                    />
                                </div>

                                <div className="w-full mb-4 md:w-4/12 block flex-row md:mx-2 items-center border-gray-300">
                                    <label
                                        className="block mr-4 mb-2 font-semibold text-gray-700"
                                        htmlFor="mobileNumber"
                                    >
                                        Mobile Number*
                                    </label>
                                    <div className="flex items-center bg-blue-500 text-gray-700 border border-gray-300 rounded-md focus-within:border-blue-500">
                                        <div className="flex items-center justify-center w-1/5">
                                            {selectedCountry
                                                ? `+${selectedCountry.mobile_code}`
                                                : "CURR"}
                                        </div>
                                        <input
                                            id="mobileNumber"
                                            className="w-full px-3 text-black py-2 border-none rounded-r-md border-gray-300 focus:border-none  focus:outline-none focus:ring-2"
                                            type="text"
                                            value={mobileNumberValue}
                                            onChange={event =>
                                                handleNumberChange(event, setMobileNumberValue)
                                            }
                                            placeholder="Enter Mobile Number"
                                        />
                                    </div>
                                </div>

                                <div className="w-full mb-4 md:w-4/12 block flex-row md:mx-2 items-center border-gray-300">
                                    <label
                                        className="block mr-4 mb-2 font-semibold text-gray-700"
                                        htmlFor="confirmMobileNumber"
                                    >
                                        Confirm Mobile Number*
                                    </label>
                                    <div className="flex items-center bg-blue-500 text-gray-700 border border-gray-300 rounded-md focus-within:border-blue-500">
                                        <div className="flex items-center justify-center w-1/5">
                                            {selectedCountry
                                                ? `+${selectedCountry.mobile_code}`
                                                : "CURR"}
                                        </div>
                                        <input
                                            id="confirmMobileNumber"
                                            className="w-full px-3 text-black py-2 border-none rounded-r-md border-gray-300 focus:border-none  focus:outline-none focus:ring-2"
                                            type="text"
                                            value={confirmMobileNumberValue}
                                            onChange={event =>
                                                handleNumberChange(
                                                    event,

                                                    setConfirmMobileNumberValue
                                                )
                                            }
                                            onPaste={e => e.preventDefault()}
                                            placeholder="Confirm Mobile Number"
                                        />
                                    </div>
                                </div>

                                {/* <div className="mb-4 md:ml-2 w-full md:w-4/12 md:mx-2">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="address"
                                    >
                                        Address 1*
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Enter Address 1"
                                        value={address1}
                                        onChange={e => setAddress1(e.target.value)}
                                        id="address"
                                        required
                                    />
                                </div>

                                <div className="mb-4 md:ml-2 w-full md:w-4/12">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="address"
                                    >
                                        Address 2
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Enter Address 2"
                                        id="address"
                                        value={address2}
                                        onChange={e => setAddress2(e.target.value)}
                                    />
                                </div> */}
                            </div>

                            {selectedValue === "Bank Transfer" &&
                                selectedCountryName !== "United States of America" && (
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="w-full mb-4 md:w-4/12 mr-2">
                                            <label
                                                className="block mb-2 font-semibold text-gray-700"
                                                htmlFor="bankName"
                                            >
                                                Bank Name*
                                            </label>

                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                id="bankName"
                                                placeholder="Select Bank Name"
                                                value={selectedBank}
                                                onChange={e => setSelectedBank(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="w-full mb-4 md:w-4/12 md:mx-2">
                                            <label
                                                className="block mb-2 font-semibold text-gray-700"
                                                htmlFor="bankBranch"
                                            >
                                                Bank Branch*
                                            </label>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                id="bankBranch"
                                                placeholder="Select Bank Branch"
                                                value={selectedBankBranch}
                                                onChange={e =>
                                                    setSelectedBankBranch(e.target.value)
                                                }
                                                required
                                            />
                                        </div>

                                        <div className=" w-full mb-4 md:w-4/12 md:ml-2">
                                            <label
                                                className="block mb-2 font-semibold text-gray-700"
                                                htmlFor="bankAccountNumber"
                                            >
                                                Bank Account Number*
                                            </label>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                type="text"
                                                placeholder="Enter Bank Account Number"
                                                value={accountNumberValue}
                                                onChange={e =>
                                                    handleNumberChange(e, setAccountNumberValue)
                                                }
                                                id="bankAccountNumber"
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                            {selectedValue === "Bank Transfer" &&
                                selectedCountryName === "United States of America" && (
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="w-full mb-4 md:w-4/12 mr-2">
                                            <label
                                                className="block mb-2 font-semibold text-gray-700"
                                                htmlFor="bankName"
                                            >
                                                Bank Name*
                                            </label>

                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                id="bankName"
                                                placeholder="Select Bank Name"
                                                value={selectedBank}
                                                onChange={e => setSelectedBank(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="w-full mb-4 md:w-4/12 md:mx-2">
                                            <label
                                                className="block mb-2 font-semibold text-gray-700"
                                                htmlFor="routingNumber"
                                            >
                                                Routing Number*
                                            </label>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                id="routingNumber"
                                                placeholder="Enter Routing Number"
                                                value={routingNumber}
                                                onChange={e => setRoutingNumber(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className=" w-full mb-4 md:w-4/12 md:ml-2">
                                            <label
                                                className="block mb-2 font-semibold text-gray-700"
                                                htmlFor="bankAccountNumber"
                                            >
                                                Bank Account Number*
                                            </label>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                type="text"
                                                placeholder="Enter Bank Account Number"
                                                value={accountNumberValue}
                                                onChange={e =>
                                                    handleNumberChange(e, setAccountNumberValue)
                                                }
                                                id="bankAccountNumber"
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                            {/* {selectedValue === "Mobile Transfer" && (
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="mobileNetworkOperator"
                                    >
                                        Mobile Network Operator*
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="mobileNetworkOperator"
                                        placeholder="Select Mobile Network Operator"
                                        value={selectedMobileOperator}
                                        onChange={e => setSelectedMobileOperator(e.target.value)}
                                        required
                                    />
                                </div>
                            )} */}

                            {/* <div className="flex flex-col md:flex-row md:justify-between">
                                <div className="mb-4 w-ful md:w-4/12 md:mr-2">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="state"
                                    >
                                        State
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Enter State"
                                        id="state"
                                        value={state}
                                        onChange={e => setState(e.target.value)}
                                    />
                                </div>

                                <div className="mb-4 flex-col md:flex-row items-center md:mx-2 w-full md:w-4/12 border-gray-300">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="city"
                                    >
                                        City
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Enter City"
                                        id="city"
                                        value={city}
                                        onChange={e => setCity(e.target.value)}
                                    />
                                </div>

                                <div className="mb-4 md:ml-2 w-full md:w-4/12">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="zipCode"
                                    >
                                        Zip Code
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Zip Code"
                                        id="zipCode"
                                        value={zipCode}
                                        onChange={e => setZipCode(e.target.value)}
                                    />
                                </div>
                            </div> */}

                            {errorMessage && (
                                <div className="flex items-center justify-center text-center text-red-600 mb-4">
                                    {errorMessage}
                                </div>
                            )}

                            <button
                                onClick={handleSubmit}
                                className="px-4 py-2 bg-blue-600 text-white mt-6 rounded-md hover:bg-blue-700 transition-colors w-full"
                            >
                                <div className="flex items-center justify-center flex-row">
                                    Add Recipient
                                    <div className="ml-2">
                                        <AddCircleOutlineIcon />
                                    </div>
                                </div>
                            </button>
                        </form>
                    </div>
                </Container>
            </AdminLayout>
        </>
    );
};

export default AddRecipient;
