import React from "react";
import { IoChevronDownOutline } from "react-icons/io5";

import CustomSelectCountry from "./CustomSelectCountry";

const DashboardCountrySelect = ({ id, selectedOption, setSelectedOption, necessary }) => {
    const handleIconClick = () => {
        // Find and click the input element to trigger the dropdown
        const inputElement = document.querySelector(`#${id}`);
        if (inputElement) {
            inputElement.click();
        }
    };

    return (
        <div className="dashboard-select relative">
            <CustomSelectCountry
                id={id}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                necessary={necessary}
            />
            <IoChevronDownOutline
                className="absolute right-0 top-1/2 -translate-y-1/2 text-white text-xl cursor-pointer !text-white"
                onClick={handleIconClick}
            />
            <style>
                {`
                    .dashboard-select input {
                        background-color: transparent !important;
                        color: white !important;
                        font-weight: 600 !important;
                        border: none !important;
                        border-radius: 0 !important;
                        padding-left: 0 !important;
                        padding-right: 24px !important;
                    }
                    .dashboard-select .absolute {
                        color: black !important;
                        margin-top: 5px !important;
                        border-radius: 8px !important;
                        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
                        background-color: white !important;
                    }
                    .dashboard-select .absolute div {
                        color: black !important;
                    }
                    .dashboard-select .hover:bg-gray-100:hover {
                        color: black !important;
                        background-color: #EFF6FF !important;
                    }
                    .dashboard-select input::placeholder {
                        color: #E5E7EB !important;
                        font-weight: 500 !important;
                    }
                    .dashboard-select .bg-gray-100 {
                        background-color: transparent !important;
                        color: white !important;
                        font-weight: 600 !important;
                        border: none !important;
                        border-radius: 0 !important;
                        padding-left: 0 !important;
                    }
                    .dashboard-select input:focus {
                        outline: none !important;
                    }
                    .dashboard-select .flex {
                        background-color: transparent !important;
                        border-bottom: 2px solid #CBD5E0 !important;
                    }
                    .dashboard-select .flex > div {
                        border: none !important;
                    }
                `}
            </style>
        </div>
    );
};

export default DashboardCountrySelect;
