import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { CiBank } from "react-icons/ci";
import { FaUser, FaEnvelope, FaPhone, FaGlobe, FaExchangeAlt } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";

import { Container } from "../components/utils";
import { toast } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import {
    getRecipientsDetails,
    updateRecipientDetails,
    getCountries,
    disableRecipient,
    deleteRecipient
} from "../services/apiService";

const Recipient = () => {
    const { recipient_id } = useParams();
    const navigate = useNavigate();
    const [recipient, setRecipient] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [showDisableConfirmation, setShowDisableConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useEffect(() => {
        const fetchRecipientDetails = async () => {
            try {
                const response = await getRecipientsDetails(recipient_id);
                setRecipient(response.data);
            } catch (error) {
                console.error("Error fetching recipient details:", error);
                toast("error", "Failed to load recipient details");
            }
        };

        fetchRecipientDetails();
    }, [recipient_id]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleEditComplete = updatedRecipient => {
        setRecipient(updatedRecipient);
        setIsEditing(false);
        toast("success", "Recipient details updated successfully");
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleDisable = () => {
        setShowDisableConfirmation(true);
    };

    const handleDelete = () => {
        setShowDeleteConfirmation(true);
    };

    const confirmDisable = async () => {
        try {
            await disableRecipient(recipient_id);
            toast("success", "Recipient disabled successfully");
            navigate("/recipients");
        } catch (error) {
            console.error("Error disabling recipient:", error);
            toast("error", "Failed to disable recipient");
        }
        setShowDisableConfirmation(false);
    };

    const confirmDelete = async () => {
        try {
            await deleteRecipient(recipient_id);
            toast("success", "Recipient deleted successfully");
            navigate("/recipients");
        } catch (error) {
            console.error("Error deleting recipient:", error);
            toast("error", "Failed to delete recipient");
        }
        setShowDeleteConfirmation(false);
    };

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    {!recipient ? (
                        <div className="flex items-center justify-center text-center mt-8">
                            Loading...
                        </div>
                    ) : isEditing ? (
                        <EditRecipient
                            recipient={recipient}
                            onSave={handleEditComplete}
                            onCancel={handleCancel}
                        />
                    ) : (
                        <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                            <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
                                <div className="bg-blue-600 px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-white">
                                        Recipient Details
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                        <RecipientField
                                            icon={<FaUser />}
                                            label="First Name"
                                            value={recipient.first_name}
                                        />
                                        <RecipientField
                                            icon={<FaUser />}
                                            label="Last Name"
                                            value={recipient.last_name}
                                        />
                                        <RecipientField
                                            icon={<FaEnvelope />}
                                            label="Email"
                                            value={recipient.email}
                                        />
                                        <RecipientField
                                            icon={<FaPhone />}
                                            label="Mobile Number"
                                            value={recipient.mobile_number}
                                        />
                                        <RecipientField
                                            icon={<FaGlobe />}
                                            label="Country"
                                            value={recipient.country}
                                        />
                                        <RecipientField
                                            icon={<FaExchangeAlt />}
                                            label="Transaction Type"
                                            value={recipient.transaction_type}
                                        />
                                        {recipient.bank !== "" && (
                                            <RecipientField
                                                icon={<CiBank />}
                                                label="Bank"
                                                value={recipient.bank}
                                            />
                                        )}
                                        {recipient.bank !== "" &&
                                            recipient.country === "United States of America" && (
                                                <RecipientField
                                                    icon={<CiBank />}
                                                    label="Routing Number"
                                                    value={recipient.routing_number}
                                                />
                                            )}
                                        {recipient.bank !== "" &&
                                            recipient.country !== "United States of America" && (
                                                <RecipientField
                                                    icon={<CiBank />}
                                                    label="Bank Branch"
                                                    value={recipient.bank_branch}
                                                />
                                            )}
                                        {recipient.bank !== "" && (
                                            <RecipientField
                                                icon={<CiBank />}
                                                label="Account Number"
                                                value={recipient.bank_account_number}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-4 sm:px-6 flex justify-end space-x-3">
                                    <button
                                        onClick={handleEdit}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Edit Details
                                    </button>
                                    <button
                                        onClick={handleDisable}
                                        className="bg-amber-500 hover:bg-amber-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Disable Recipient
                                    </button>
                                    <button
                                        onClick={handleDelete}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Delete Recipient
                                    </button>
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <button
                                    onClick={() => navigate("/recipients")}
                                    className="text-blue-600 hover:text-blue-800"
                                >
                                    Back to Recipients List
                                </button>
                            </div>
                        </div>
                    )}
                </Container>
            </AdminLayout>
            {showDisableConfirmation && (
                <ConfirmationDialog
                    title="Disable Recipient"
                    message="Are you sure you want to disable this recipient? They will no longer be able to receive transfers."
                    onConfirm={confirmDisable}
                    onCancel={() => setShowDisableConfirmation(false)}
                />
            )}
            {showDeleteConfirmation && (
                <ConfirmationDialog
                    title="Delete Recipient"
                    message="Are you sure you want to delete this recipient? This action cannot be undone."
                    onConfirm={confirmDelete}
                    onCancel={() => setShowDeleteConfirmation(false)}
                />
            )}
        </>
    );
};

const RecipientField = ({ icon, label, value }) => (
    <div className="flex items-center space-x-3">
        <div className="flex-shrink-0 text-gray-400">{icon}</div>
        <div className="flex-grow">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <p className="mt-1 text-sm text-gray-900">{value}</p>
        </div>
    </div>
);

const EditRecipient = ({ recipient, onSave, onCancel }) => {
    const [editedRecipient, setEditedRecipient] = useState(recipient);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries();
    }, []);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setEditedRecipient(prev => {
            if (name === "country" && prev.country !== value) {
                return { ...prev, [name]: value, mobile_number: "" };
            }
            return { ...prev, [name]: value };
        });
        setErrors(prev => ({ ...prev, [name]: undefined }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (validateForm()) {
            setShowConfirmation(true);
        }
    };

    const handleConfirm = async () => {
        try {
            const response = await updateRecipientDetails(recipient.recipient_id, editedRecipient);
            if (response.status === 200) {
                onSave(editedRecipient);
            } else {
                setErrors({ form: "Failed to update recipient details" });
            }
        } catch (error) {
            console.error("Error updating recipient details:", error);
            setErrors({ form: "An unexpected error occurred" });
        }
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const validateForm = () => {
        let newErrors = {};

        if (!editedRecipient.country) newErrors.country = "Country is required";
        if (!editedRecipient.transaction_type)
            newErrors.transaction_type = "Transaction type is required";
        if (!editedRecipient.first_name.trim()) newErrors.first_name = "First name is required";
        if (!editedRecipient.last_name.trim()) newErrors.last_name = "Last name is required";
        if (!editedRecipient.email.trim()) newErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(editedRecipient.email)) newErrors.email = "Email is invalid";
        if (!editedRecipient.mobile_number.trim())
            newErrors.mobile_number = "Mobile number is required";

        if (editedRecipient.transaction_type === "Bank Transfer") {
            if (!editedRecipient.bank) newErrors.bank = "Bank name is required";
            if (!editedRecipient.bank_account_number.trim())
                newErrors.bank_account_number = "Account number is required";

            if (editedRecipient.country === "United States of America") {
                if (!editedRecipient.routing_number)
                    newErrors.routing_number = "Routing Number is required";
            } else {
                if (!editedRecipient.bank_branch) newErrors.bank_branch = "Bank branch is required";
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
                <div className="bg-blue-600 px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-white">
                        Edit Recipient Details
                    </h3>
                </div>
                <form onSubmit={handleSubmit} className="px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <EditField
                            label="First Name"
                            name="first_name"
                            value={editedRecipient.first_name}
                            onChange={handleInputChange}
                            error={errors.first_name}
                        />
                        <EditField
                            label="Last Name"
                            name="last_name"
                            value={editedRecipient.last_name}
                            onChange={handleInputChange}
                            error={errors.last_name}
                        />
                        <EditField
                            label="Email"
                            name="email"
                            type="email"
                            value={editedRecipient.email}
                            onChange={handleInputChange}
                            error={errors.email}
                        />
                        <MobileNumberInput
                            label="Mobile Number"
                            name="mobile_number"
                            value={editedRecipient.mobile_number}
                            onChange={handleInputChange}
                            country={editedRecipient.country}
                            error={errors.mobile_number}
                        />
                        <CountrySelector
                            label="Country"
                            name="country"
                            value={`${editedRecipient.country}`}
                            onChange={handleInputChange}
                            countries={countries}
                            error={errors.country}
                        />
                        <TransactionTypeSelector
                            label="Transaction Type"
                            name="transaction_type"
                            value={editedRecipient.transaction_type}
                            onChange={handleInputChange}
                            error={errors.transaction_type}
                        />
                        {editedRecipient.transaction_type === "Bank Transfer" && (
                            <>
                                <EditField
                                    label="Bank Name"
                                    name="bank"
                                    value={editedRecipient.bank}
                                    onChange={handleInputChange}
                                    error={errors.bank}
                                />
                                {editedRecipient.country.trim() === "United States of America" ? (
                                    <EditField
                                        label="Routing Number"
                                        name="routing_number"
                                        value={editedRecipient.routing_number}
                                        onChange={handleInputChange}
                                        error={errors.routing_number}
                                    />
                                ) : (
                                    <EditField
                                        label="Bank Branch"
                                        name="bank_branch"
                                        value={editedRecipient.bank_branch}
                                        onChange={handleInputChange}
                                        error={errors.bank_branch}
                                    />
                                )}
                                <EditField
                                    label="Account Number"
                                    name="bank_account_number"
                                    value={editedRecipient.bank_account_number}
                                    onChange={handleInputChange}
                                    error={errors.bank_account_number}
                                />
                            </>
                        )}
                    </div>
                    {errors.form && <ErrorMessage message={errors.form} />}
                    <div className="mt-6 flex justify-end space-x-3">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
            {showConfirmation && (
                <ConfirmationDialog
                    title="Save Changes"
                    message="Are you sure you want to save the changes?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

const ConfirmationDialog = ({ title, message, onConfirm, onCancel }) => {
    return (
        <div
            className="fixed z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                ></div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                    className="text-lg leading-6 font-medium text-gray-900"
                                    id="modal-title"
                                >
                                    {title}
                                </h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">{message}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onConfirm}
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EditField = ({ label, name, value, onChange, error, type = "text" }) => (
    <div>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        <input
            type={type}
            name={name}
            id={name}
            value={value}
            onChange={onChange}
            className={`mt-1 block w-full border ${
                error ? "border-red-500" : "border-gray-300"
            } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
        />
        {error && <ErrorMessage message={error} />}
    </div>
);

const ErrorMessage = ({ message }) => (
    <div className="mt-2 flex items-center justify-center text-sm text-red-600">{message}</div>
);

const CountrySelector = ({ label, name, value, onChange, countries }) => (
    <div>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        <select
            name={name}
            id={name}
            value={value.trim()}
            onChange={onChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
            {countries.map((country, index) => (
                <option key={index} value={country.country_name.trim()}>
                    {country.country_name}
                </option>
            ))}
        </select>
    </div>
);

const TransactionTypeSelector = ({ label, name, value, onChange }) => {
    const transactionTypes = ["Bank Transfer", "Mobile Transfer", "Cash Pickup"];

    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <select
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
                {transactionTypes.map((type, index) => (
                    <option key={index} value={type}>
                        {type}
                    </option>
                ))}
            </select>
        </div>
    );
};

const MobileNumberInput = ({ label, name, value, onChange, country }) => {
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries();
    }, [countries]);
    useEffect(() => {
        if (countries) {
            setCountryCode(countries.find(c => c.country_name.trim() === country)?.mobile_code);
        }
    }, [country, countries]);

    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    {`+${countryCode}`}
                </span>
                <input
                    type="text"
                    name={name}
                    id={name}
                    value={value.slice(`+${countryCode}`.length)}
                    onChange={onChange}
                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300"
                />
            </div>
        </div>
    );
};

export default Recipient;
