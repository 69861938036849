import React from "react";

const NumberTextInput = ({
    placeholder,
    className,
    formattedValue,
    setFormattedValue,
    readOnly = false
}) => {
    const formatNumberWithCommas = number => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleChange = e => {
        const rawValue = e.target.value.replace(/,/g, ""); // Remove existing commas
        if (!isNaN(rawValue)) {
            // Ensure it's a valid number
            setFormattedValue(formatNumberWithCommas(rawValue));
        }
    };

    return (
        <input
            id="number-input"
            type="text"
            className={className}
            value={formattedValue}
            onChange={handleChange}
            placeholder={placeholder}
            readOnly={readOnly}
        />
    );
};

export default NumberTextInput;
