import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "../helpers";
import { toast } from "../helpers";

const useIdleTimer = (idleTime = 300000, warningTime = 60000) => {
    const navigate = useNavigate();
    const [isIdle, setIsIdle] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [lastActiveTime, setLastActiveTime] = useState(Date.now());

    // Handle browser close/refresh
    useEffect(() => {
        const handleTabClose = event => {
            // Check if it's a refresh (most browsers)
            if (
                event.persisted ||
                (window.performance && window.performance.navigation.type === 1)
            ) {
                return;
            }

            // Store a timestamp in sessionStorage
            sessionStorage.setItem("closingTime", Date.now().toString());

            // Perform logout only on actual tab/browser close
            logout(navigate);
        };

        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Store timestamp when tab becomes hidden
                sessionStorage.setItem("tabHiddenTime", Date.now().toString());
            } else {
                // Check how long the tab was hidden
                const hiddenTime = sessionStorage.getItem("tabHiddenTime");
                if (hiddenTime) {
                    const timeHidden = Date.now() - parseInt(hiddenTime);
                    if (timeHidden >= idleTime) {
                        logout(navigate);
                    }
                }
            }
        };

        // Add event listeners
        window.addEventListener("beforeunload", handleTabClose);
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Cleanup
        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [navigate, idleTime]);

    useEffect(() => {
        let idleTimer;
        let warningTimer;
        let checkInterval;

        const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart", "click"];

        const resetTimer = () => {
            if (document.hasFocus()) {
                if (isIdle) setIsIdle(false);
                if (showWarning) setShowWarning(false);
                setLastActiveTime(Date.now());

                clearTimeout(warningTimer);
                clearTimeout(idleTimer);

                warningTimer = setTimeout(() => {
                    if (Date.now() - lastActiveTime >= idleTime - warningTime) {
                        setShowWarning(true);
                        toast("warning", "You will be logged out due to inactivity in 1 minute");
                    }
                }, idleTime - warningTime);

                idleTimer = setTimeout(() => {
                    if (Date.now() - lastActiveTime >= idleTime) {
                        setIsIdle(true);
                        logout(navigate);
                    }
                }, idleTime);
            }
        };

        checkInterval = setInterval(() => {
            const timeSinceLastActive = Date.now() - lastActiveTime;

            if (timeSinceLastActive >= idleTime) {
                setIsIdle(true);
                logout(navigate);
            } else if (timeSinceLastActive >= idleTime - warningTime && !showWarning) {
                setShowWarning(true);
                toast("warning", "You will be logged out due to inactivity in 1 minute");
            }
        }, 30000);

        // Add event listeners
        events.forEach(event => {
            document.addEventListener(event, resetTimer);
        });

        // Initial timer
        resetTimer();

        // Cleanup
        return () => {
            clearTimeout(idleTimer);
            clearTimeout(warningTimer);
            clearInterval(checkInterval);
            events.forEach(event => {
                document.removeEventListener(event, resetTimer);
            });
        };
    }, [idleTime, warningTime, navigate, isIdle, showWarning, lastActiveTime]);

    return { isIdle, showWarning };
};

export default useIdleTimer;
