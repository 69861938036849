import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "../components/buttons";
import { Checkbox } from "../components/field";
import { Link, Loader } from "../components/utils";
import { toast } from "../helpers";
import AuthLayout from "../layouts/AuthLayout";
import { userRegistration } from "../services/apiService";

const Input = ({ label, id, type, placeholder, value, onChange, error }) => {
    return (
        <div>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <input
                type={type}
                name={id}
                id={id}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            {error && (
                <p className="mt-2 text-sm text-red-600">
                    {Array.isArray(error) ? error.join(", ") : error}
                </p>
            )}
        </div>
    );
};

const Register = () => {
    const navigate = useNavigate();
    const defaultMessage = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: ""
    };

    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState(defaultMessage);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [termsError, setTermsError] = useState("");

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
        if (termsError) {
            setTermsError("");
        }
    };

    const validateFields = () => {
        let newErrorMessage = { ...defaultMessage };
        let isValid = true;

        if (!firstName) {
            newErrorMessage.first_name = "First name is required";
            isValid = false;
        }

        if (!lastName) {
            newErrorMessage.last_name = "Last name is required";
            isValid = false;
        }

        if (!email) {
            newErrorMessage.email = "Email address is required";
            isValid = false;
        }

        if (!password) {
            newErrorMessage.password = "Password is required";
            isValid = false;
        }

        if (!confirmPassword) {
            newErrorMessage.confirm_password = "Confirm password is required";
            isValid = false;
        }

        if (password !== confirmPassword) {
            newErrorMessage.confirm_password = "Passwords do not match";
            isValid = false;
        }

        setErrorMessage(newErrorMessage);
        return isValid;
    };

    const checkSanctionList = async (firstName, lastName) => {
        try {
            const response = await axios.get(
                "http://127.0.0.1:5000/api/v1/sanction-list/get-all-sanction-data"
            );
            return response.data.data.some(
                person =>
                    person.first_name.toLowerCase() === firstName.toLowerCase() &&
                    person.second_name.toLowerCase() === lastName.toLowerCase()
            );
        } catch (error) {
            console.error("Error checking sanction list:", error);
            return false;
        }
    };

    const postFlaggedUser = async userData => {
        try {
            await axios.post(
                "http://127.0.0.1:5000/api/v1/flagged-users/post-flagged-users",
                userData
            );
        } catch (error) {
            console.error("Error posting flagged user:", error);
        }
    };

    const register = async () => {
        if (!validateFields()) {
            return;
        }

        if (!agreeTerms) {
            setTermsError("You must agree to the terms and conditions.");
            return;
        }

        setLoading(true);
        setTermsError("");
        try {
            const isSanctioned = await checkSanctionList(firstName, lastName);

            let details = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                confirm_password: confirmPassword
            };

            const user = await userRegistration(details);

            if (user.status === 200) {
                setInvalid(false);

                if (isSanctioned) {
                    await postFlaggedUser({
                        first_name: firstName,
                        second_name: lastName,
                        third_name: "", // Add third name if available
                        email: email
                    });
                }

                toast("success", user.description);
                navigate("/login");
            } else {
                setInvalid(true);
                toast("error", "An error occurred. Please try again.");
            }
        } catch (error) {
            setInvalid(true);
            toast("error", "Connection failed");
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout
            title={
                <>
                    Welcome to <br /> Links Remit
                </>
            }
        >
            <h3 className="text-center text-xl font-semibold text-gray-700">
                Create Links Remit Account
            </h3>
            <p className="text-center text-sm mt-2 mb-8">
                Account activation link will be sent to your email.
            </p>

            {invalid && (
                <div className="my-2 text-center text-red-600 bg-red-100 py-2 rounded-md">
                    Invalid details. Check your input and try again
                </div>
            )}

            <form className="space-y-5">
                <Input
                    label="First Name"
                    id="first_name"
                    type="text"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    error={errorMessage.first_name}
                />

                <Input
                    label="Last Name"
                    id="last_name"
                    type="text"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    error={errorMessage.last_name}
                />

                <Input
                    label="Email Address"
                    id="email"
                    type="email"
                    placeholder="Enter email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={errorMessage.email}
                />

                <Input
                    label="Password"
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    error={errorMessage.password}
                />

                <Input
                    label="Confirm Password"
                    id="confirm_password"
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    error={errorMessage.confirm_password}
                />

                <div>
                    <Checkbox
                        id="agreeTerms"
                        label="I agree to privacy policy & terms"
                        checked={agreeTerms}
                        onChange={handleCheckboxChange}
                    />
                </div>

                {termsError && <div className="text-red-600 text-sm">{termsError}</div>}

                <PrimaryButton onClick={register} disabled={loading}>
                    {loading && <Loader color="white" />}
                    <span>Sign up</span>
                </PrimaryButton>
                <p className="text-sm text-center">
                    Already have an account? <Link href="/login">Login</Link>
                </p>
            </form>
        </AuthLayout>
    );
};

export default Register;
