import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { Container } from "../components/utils";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { getTransactionDetails, getRecipients } from "../services/apiService"; // You'll need to create this function

const TransactionDetails = () => {
    const [transaction, setTransaction] = useState(null);
    const [recipients, setRecipients] = useState([]);

    const [error, setError] = useState(null);
    const { remittance_id } = useParams();

    useEffect(() => {
        const fetchTransactionDetails = async () => {
            try {
                const response = await getTransactionDetails(remittance_id);
                setTransaction(response.data);
            } catch (err) {
                setError("Failed to fetch transaction details");
                console.error(err);
            }
        };
        const fetchRecipients = async () => {
            try {
                const response = await getRecipients();
                setRecipients(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTransactionDetails();
        fetchRecipients();
    }, [remittance_id]);

    if (error) return <div>{error}</div>;
    if (!transaction) return <div>No transaction found</div>;

    return (
        <AdminLayout>
            <Nav />
            <Container>
                <div className="container flex flex-col flex-1 p-4">
                    <Link to={"/transactions"}>
                        <h2 className="text-xl hover:text-blue-500 text-gray-500 flex mb-4 flex-row items-center justify-start">
                            <ArrowBackIcon className="mr-2" />
                            Transactions
                        </h2>
                    </Link>
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h1 className="text-xl font-semibold text-blue-700 mb-4">
                            Transaction Details
                        </h1>
                        <div className="flex justify-center flex-col">
                            <div>
                                {[
                                    { label: "Remittance ID", value: transaction.remittance_id },
                                    {
                                        label: "Recipient",
                                        value: `${
                                            recipients.find(
                                                recipient =>
                                                    recipient.recipient_id ===
                                                    transaction.recipient_id
                                            )?.first_name
                                        } ${
                                            recipients.find(
                                                recipient =>
                                                    recipient.recipient_id ===
                                                    transaction.recipient_id
                                            )?.last_name
                                        }`
                                    },
                                    { label: "Sender's Country", value: transaction.from_country },
                                    { label: "Recipient's Country", value: transaction.to_country },
                                    {
                                        label: "Amount Sent",
                                        value: `${transaction.amount_sent} ${transaction.amount_sent_currency}`
                                    },
                                    {
                                        label: "Amount Received",
                                        value: `${transaction.amount_received} ${transaction.amount_received_currency}`
                                    },
                                    { label: "Exchange Rate", value: transaction.exchange_rate },
                                    { label: "Transfer Fee", value: transaction.transfer_fee },
                                    {
                                        label: "Status",
                                        value:
                                            parseInt(transaction.status) === 1
                                                ? "In Progress"
                                                : parseInt(transaction.status) === 2
                                                ? "Received"
                                                : parseInt(transaction.status) === 0
                                                ? "Failed"
                                                : "Unknown"
                                    },
                                    {
                                        label: "Date",
                                        value: formatTimestamp(transaction.time_stamp).date
                                    },
                                    {
                                        label: "Time",
                                        value: formatTimestamp(transaction.time_stamp).time
                                    }
                                ].map((item, index) => (
                                    <div key={index} className="flex py-3 border-b border-gray-200">
                                        <p className="w-1/3 font-semibold">{item.label}:</p>
                                        <p className="w-2/3">{item.value}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </AdminLayout>
    );
};

export default TransactionDetails;

function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);

    const getOrdinalSuffix = day => {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const dayOfMonth = dateObj.getDate();
    const ordinalSuffix = getOrdinalSuffix(dayOfMonth);
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    // Format the date string
    const formattedDate = `${dayOfMonth}${ordinalSuffix} ${month} ${year}`;

    // Get hour, minute, and period (am/pm)
    let hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    let period = "am";

    // Convert hour to 12-hour format and determine period (am/pm)
    if (hour >= 12) {
        period = "pm";
        if (hour > 12) hour -= 12; // Convert from 24-hour to 12-hour format
    }
    if (hour === 0) hour = 12; // Midnight should be 12am

    // Format the time string
    const formattedTime = `${hour}:${minute.toString().padStart(2, "0")}${period}`;

    return { date: formattedDate, time: formattedTime };
}
