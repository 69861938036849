import config from "../config";

const Footer = () => {
    return (
        <footer className="flex items-center justify-center mt-4 mb-3 text-sm text-gray-600">
            <p className="text-center">
                Copyright © 2024 <b> {config.APP_NAME}.</b> All rights reserved.
            </p>
        </footer>
    );
};

export default Footer;
