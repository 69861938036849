import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import {
    FaTwitter,
    FaEnvelope,
    FaInstagram,
    FaFacebook,
    FaPhone,
    FaLinkedin,
    FaBell,
    FaExclamationTriangle,
    FaShieldAlt,
    FaUser,
    FaCreditCard,
    FaChevronDown
} from "react-icons/fa";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import config from "../config";
import { toast } from "../helpers";
import Nav from "../layouts/Nav";
import {
    changePassword,
    deactivateAccount,
    getProfile,
    updateProfile,
    getCountries,
    enable2FA,
    verify2FAOTP,
    disable2FA
} from "../services/apiService";

const Settings = () => {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [twoFactor, setTwoFactor] = useState(false);
    const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [notificationSettings, setNotificationSettings] = useState({
        announcements: false,
        sendPayment: false,
        receivePayment: false,
        requestPayment: false,
        paymentProblem: false,
        marketing: false,
        unusualActivity: false
    });

    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState({
        current: false,
        new: false,
        confirm: false
    });

    const [activeTab, setActiveTab] = useState("profile");
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [twoFactorLoading, setTwoFactorLoading] = useState(false);

    useEffect(() => {
        let timer;
        if (errorMessage) {
            timer = setTimeout(() => {
                setErrorMessage("");
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [errorMessage]);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await getProfile();
                console.log("Profile Response:", response);

                if (response?.status === 200 && response?.data) {
                    const twoFactorEnabled = response.data.two_factor_auth_enabled === 1;
                    console.log("Setting 2FA state to:", twoFactorEnabled);
                    setTwoFactor(twoFactorEnabled);
                }
            } catch (error) {
                console.error("Error fetching profile:", error);
                toast("error", "Failed to load profile data");
            }
        };

        fetchProfileData();
    }, []);

    const handleConfirmChangePassword = async () => {
        setShowPasswordForm(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        const data = {
            current_password: currentPassword,
            new_password: newPassword
        };
        try {
            const response = await changePassword(data);
            if (response.status === 200) {
                toast("success", "Password changed successfully");
            }
        } catch (error) {
            toast("error", "Failed to change password");
        }
    };
    const handleSubmitPasswordChange = e => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setErrorMessage("Passwords do not match");
            return;
        }

        if (newPassword.length < 6) {
            setErrorMessage("Password must be at least 6 characters long");
            return;
        }

        if (newPassword === currentPassword) {
            setErrorMessage("New password must be different from the current password");
            return;
        }

        if (
            newPassword === "123456" ||
            newPassword === "password123" ||
            newPassword === "password" ||
            newPassword === "abc123" ||
            newPassword === "password1" ||
            newPassword === "admin" ||
            newPassword === "1234567890" ||
            newPassword === "letmein" ||
            newPassword === "iloveyou" ||
            newPassword === "password!" ||
            newPassword === "password1234" ||
            newPassword === "welcome" ||
            newPassword === "monkey" ||
            newPassword === "123123" ||
            newPassword === "12345678" ||
            newPassword === "1234567" ||
            newPassword === "123456789" ||
            newPassword === "1234567890" ||
            newPassword === "password123!" ||
            newPassword === "password1234!" ||
            newPassword === "password12345!" ||
            newPassword === "password123456!" ||
            newPassword === "password1234567!" ||
            newPassword === "password12345678!" ||
            newPassword === "password123456789"
        ) {
            setErrorMessage("Password is too common");
            return;
        }

        setShowPasswordForm(true);
    };

    const togglePasswordVisibility = field => {
        setPasswordVisible(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const handleNotificationChange = setting => {
        setNotificationSettings(prev => ({ ...prev, [setting]: !prev[setting] }));
    };

    const handleSaveNotifications = () => {
        console.log("Saving notification settings:", notificationSettings);
    };

    const handleCancelChangePassword = () => {
        setShowPasswordForm(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
    };

    const handleSubmitQuestion = e => {
        e.preventDefault();
        // Here you would typically send an email with the form data
        console.log("Question submitted");
    };
    const handleTwoFactorAuth = async () => {
        if (twoFactorLoading) return;
        setTwoFactorLoading(true);

        try {
            if (!twoFactor) {
                const response = await enable2FA();
                if (response?.status === 200) {
                    setShowTwoFactorModal(true);
                } else {
                    toast("error", "Failed to initiate 2FA");
                }
            } else {
                const response = await disable2FA();
                if (response?.status === 200) {
                    setTwoFactor(false);
                    toast("success", "Two-factor authentication disabled");
                } else {
                    toast("error", "Failed to disable 2FA");
                }
            }
        } catch (error) {
            console.error("Error:", error);
            toast("error", `Failed to ${!twoFactor ? "enable" : "disable"} 2FA`);
        } finally {
            setTwoFactorLoading(false);
        }
    };

    const TwoFactorModal = ({ isOpen, onClose }) => {
        const [code, setCode] = useState("");
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState("");

        const handleSubmit = async e => {
            e.preventDefault();
            setLoading(true);
            setError("");

            try {
                const response = await verify2FAOTP(code);

                if (response?.status === 200) {
                    setTwoFactor(true);
                    toast("success", "Two-factor authentication enabled");
                    onClose();
                } else {
                    setError("Invalid verification code");
                }
            } catch (error) {
                console.error("Error:", error);
                setError("Failed to verify code");
            } finally {
                setLoading(false);
            }
        };

        if (!isOpen) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg w-full max-w-md">
                    <h3 className="text-xl font-medium mb-4">
                        Enter Two-Factor Authentication Code
                    </h3>
                    <p className="text-gray-600 mb-4">
                        Please enter the verification code sent to your email/phone.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            placeholder="Enter 6-digit code"
                            className="w-full p-2 border rounded mb-4"
                            maxLength="6"
                            pattern="\d{6}"
                            required
                        />
                        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                disabled={loading}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                                disabled={loading}
                            >
                                {loading ? "Verifying..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    const handleDeactivateAccount = () => {
        setShowDeactivateModal(true);
    };

    const confirmDeactivateAccount = async () => {
        try {
            const response = await deactivateAccount();

            if (response.status === 200) {
                toast("success", "Account deactivated successfully");
                config.AUTH.DRIVER.removeItem("token");
                config.AUTH.DRIVER.removeItem("user");
                config.AUTH.DRIVER.removeItem("isVerified");
                navigate("/login");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const ProfileInformationTab = () => {
        const [formData, setFormData] = useState({
            first_name: "",
            middle_name: "",
            last_name: "",
            email: "",
            country: "",
            mobile_number: ""
        });
        const [editableFields, setEditableFields] = useState({
            middle_name: false,
            country: false,
            mobile_number: false
        });
        const [countries, setCountries] = useState([]);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const [profileRes, countriesRes] = await Promise.all([
                        getProfile(),
                        getCountries()
                    ]);

                    setFormData(profileRes.data);
                    setCountries(countriesRes.data);
                } catch (error) {
                    console.error(error);
                    toast("error", "Failed to load data");
                }
            };

            fetchData();
        }, []);

        const handleInputChange = e => {
            const { name, value } = e.target;
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        };

        const toggleEdit = field => {
            setEditableFields(prev => ({
                ...prev,
                [field]: !prev[field]
            }));
        };

        const handleSubmit = async e => {
            e.preventDefault();
            setLoading(true);

            try {
                await updateProfile(formData);
                toast("success", "Profile updated successfully");
                // Reset all edit states to false after successful update
                setEditableFields({
                    middle_name: false,
                    country: false,
                    mobile_number: false
                });
            } catch (error) {
                console.error(error);
                toast("error", "Failed to update profile");
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-semibold mb-6 flex items-center">
                    <FaUser className="mr-2 text-blue-500" />
                    Profile Information
                </h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Read-only First Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                First Name
                            </label>
                            <input
                                type="text"
                                value={formData.first_name}
                                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md"
                                disabled
                            />
                        </div>

                        {/* Editable Middle Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Middle Name
                            </label>
                            <div className="flex items-center gap-2">
                                <input
                                    type="text"
                                    name="middle_name"
                                    value={formData.middle_name}
                                    onChange={handleInputChange}
                                    className={`w-full px-3 py-2 border border-gray-300 rounded-md ${
                                        !editableFields.middle_name ? "bg-gray-100" : "bg-white"
                                    }`}
                                    disabled={!editableFields.middle_name}
                                />
                                <button
                                    type="button"
                                    onClick={() => toggleEdit("middle_name")}
                                    className="px-3 py-2 text-blue-600 hover:text-blue-800"
                                >
                                    {editableFields.middle_name ? "Done" : "Edit"}
                                </button>
                            </div>
                        </div>

                        {/* Read-only Last Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Last Name
                            </label>
                            <input
                                type="text"
                                value={formData.last_name}
                                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md"
                                disabled
                            />
                        </div>

                        {/* Read-only Email */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Email Address
                            </label>
                            <input
                                type="email"
                                value={formData.email}
                                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md"
                                disabled
                            />
                        </div>

                        {/* Editable Country */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Country
                            </label>
                            <div className="flex items-center gap-2">
                                <select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    className={`w-full px-3 py-2 border border-gray-300 rounded-md ${
                                        !editableFields.country ? "bg-gray-100" : "bg-white"
                                    }`}
                                    disabled={!editableFields.country}
                                >
                                    <option value="">Select Country</option>
                                    {countries.map(country => (
                                        <option key={country.code} value={country.code}>
                                            {country.country_name}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    type="button"
                                    onClick={() => toggleEdit("country")}
                                    className="px-3 py-2 text-blue-600 hover:text-blue-800"
                                >
                                    {editableFields.country ? "Done" : "Edit"}
                                </button>
                            </div>
                        </div>

                        {/* Editable Mobile Number */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Mobile Number
                            </label>
                            <div className="flex items-center gap-2">
                                <input
                                    type="tel"
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={handleInputChange}
                                    className={`w-full px-3 py-2 border border-gray-300 rounded-md ${
                                        !editableFields.mobile_number ? "bg-gray-100" : "bg-white"
                                    }`}
                                    disabled={!editableFields.mobile_number}
                                />
                                <button
                                    type="button"
                                    onClick={() => toggleEdit("mobile_number")}
                                    className="px-3 py-2 text-blue-600 hover:text-blue-800"
                                >
                                    {editableFields.mobile_number ? "Done" : "Edit"}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Submit button - only visible if any field is being edited */}
                    {(editableFields.middle_name ||
                        editableFields.country ||
                        editableFields.mobile_number) && (
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={loading}
                                className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                                    loading ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            >
                                {loading ? "Updating..." : "Update Profile"}
                            </button>
                        </div>
                    )}
                </form>
            </div>
        );
    };

    const handleDownloadData = async e => {
        e.preventDefault();
        try {
            const response = await fetch("/api/download-data", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: e.target.email.value })
            });

            if (response.ok) {
                toast("success", "Data download link sent to your email");
            } else {
                toast("error", "Failed to send data download link");
            }
        } catch (error) {
            console.error("Error:", error);
            toast("error", "An error occurred while requesting data download");
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case "profile":
                return <ProfileInformationTab />;
            case "changePassword":
                return (
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-6 flex items-center">
                            <FaShieldAlt className="mr-2 text-green-500" />
                            Change Password
                        </h2>
                        <form onSubmit={handleSubmitPasswordChange} className="space-y-4">
                            <div className="relative mb-4">
                                <input
                                    type={passwordVisible.current ? "text" : "password"}
                                    value={currentPassword}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                    placeholder="Current Password"
                                    className="w-full p-2 border rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => togglePasswordVisibility("current")}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                >
                                    {passwordVisible.current ? (
                                        <HiEyeOff size={24} />
                                    ) : (
                                        <HiEye size={24} />
                                    )}
                                </button>
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type={passwordVisible.new ? "text" : "password"}
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    placeholder="New Password"
                                    className="w-full p-2 border rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => togglePasswordVisibility("new")}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                >
                                    {passwordVisible.new ? (
                                        <HiEyeOff size={24} />
                                    ) : (
                                        <HiEye size={24} />
                                    )}
                                </button>
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type={passwordVisible.confirm ? "text" : "password"}
                                    value={confirmNewPassword}
                                    onChange={e => setConfirmNewPassword(e.target.value)}
                                    placeholder="Confirm New Password"
                                    className="w-full p-2 border rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => togglePasswordVisibility("confirm")}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                >
                                    {passwordVisible.confirm ? (
                                        <HiEyeOff size={24} />
                                    ) : (
                                        <HiEye size={24} />
                                    )}
                                </button>
                            </div>
                            {errorMessage && (
                                <div className="flex items-center justify-center">
                                    <p className="text-red-600 text-sm">{errorMessage}</p>
                                </div>
                            )}
                            <button
                                type="submit"
                                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                            >
                                Change Password
                            </button>
                        </form>
                    </section>
                );
            case "downloadData":
                return (
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-6 flex items-center">
                            <FaEnvelope className="mr-2 text-blue-500" />
                            Download Your Data
                        </h2>
                        <form onSubmit={handleDownloadData} className="space-y-4">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="w-full p-2 border rounded"
                                required
                            />
                            <button
                                type="submit"
                                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                            >
                                Send Data
                            </button>
                        </form>
                    </section>
                );
            case "paymentDetails":
                return (
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-6 flex items-center">
                            <FaCreditCard className="mr-2 text-blue-500" />
                            Payment Details
                        </h2>
                        {/* Payment details form or information */}
                    </section>
                );
            case "deactivateAccount":
                return (
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-6 flex items-center">
                            <FaExclamationTriangle className="mr-2 text-red-500" />
                            Deactivate Account
                        </h2>
                        <p className="text-gray-600 mb-4">
                            Warning: You will not be able to access your account after deactivation.
                            You will have to reactivate your account to regain access.
                        </p>
                        <button
                            onClick={handleDeactivateAccount}
                            className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                        >
                            Deactivate Account
                        </button>
                    </section>
                );
            case "notifications":
                return (
                    // Notifications Section
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-4 flex items-center">
                            <FaBell className="mr-2 text-blue-500" />
                            Notification Preferences
                        </h2>
                        <p className="text-gray-600 mb-6">
                            Customize how and when you receive notifications. We'll always send you
                            important account-related and security notifications.
                        </p>
                        <div className="space-y-6">
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="announcements"
                                    checked={notificationSettings.announcements}
                                    onChange={() => handleNotificationChange("announcements")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="announcements"
                                        className="font-medium text-gray-700"
                                    >
                                        Announcements
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Receive updates about new features, product changes, and
                                        important news.
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="sendPayment"
                                    checked={notificationSettings.sendPayment}
                                    onChange={() => handleNotificationChange("sendPayment")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="sendPayment"
                                        className="font-medium text-gray-700"
                                    >
                                        Send Payment Notifications
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Get notified when you send a payment to confirm successful
                                        transactions.
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="receivePayment"
                                    checked={notificationSettings.receivePayment}
                                    onChange={() => handleNotificationChange("receivePayment")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="receivePayment"
                                        className="font-medium text-gray-700"
                                    >
                                        Receive Payment Notifications
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Get notified when you receive a payment to stay on top of
                                        your finances.
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="requestPayment"
                                    checked={notificationSettings.requestPayment}
                                    onChange={() => handleNotificationChange("requestPayment")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="requestPayment"
                                        className="font-medium text-gray-700"
                                    >
                                        Payment Request Notifications
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Receive notifications when someone requests a payment from
                                        you.
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="paymentProblem"
                                    checked={notificationSettings.paymentProblem}
                                    onChange={() => handleNotificationChange("paymentProblem")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="paymentProblem"
                                        className="font-medium text-gray-700"
                                    >
                                        Payment Problem Alerts
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Get notified if there's an issue with any of your payments
                                        or transactions.
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="marketing"
                                    checked={notificationSettings.marketing}
                                    onChange={() => handleNotificationChange("marketing")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="marketing"
                                        className="font-medium text-gray-700"
                                    >
                                        Marketing Communications
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Receive promotional offers, tips, and product updates (you
                                        can unsubscribe anytime).
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <input
                                    type="checkbox"
                                    id="unusualActivity"
                                    checked={notificationSettings.unusualActivity}
                                    onChange={() => handleNotificationChange("unusualActivity")}
                                    className="form-checkbox h-5 w-5 text-blue-600 mt-1"
                                />
                                <div className="ml-3">
                                    <label
                                        htmlFor="unusualActivity"
                                        className="font-medium text-gray-700"
                                    >
                                        Unusual Activity Alerts
                                    </label>
                                    <p className="text-gray-500 text-sm">
                                        Get immediate notifications if we detect any suspicious
                                        activity on your account.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={handleSaveNotifications}
                            className="mt-6 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                        >
                            Save Notification Preferences
                        </button>
                    </section>
                );
            case "help":
                return (
                    // Help Section
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-4 flex items-center">
                            <FaExclamationTriangle className="mr-2 text-yellow-500" />
                            Help & Support
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <h3 className="text-xl font-medium mb-2">Contact Us</h3>
                                <div className="flex items-center space-x-2">
                                    <FaPhone className="text-gray-600" />
                                    <span>+1 (555) 123-4567</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaEnvelope className="text-gray-600" />
                                    <span>support@example.com</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaTwitter className="text-blue-400" />
                                    <span>@examplesupport</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaFacebook className="text-blue-600" />
                                    <span>ExampleSupport</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaInstagram className="text-pink-600" />
                                    <span>@examplesupport</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaLinkedin className="text-blue-700" />
                                    <span>Example Support</span>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-xl font-medium mb-2">Ask a Question</h3>
                                <form onSubmit={handleSubmitQuestion} className="space-y-4">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        className="w-full p-2 border rounded"
                                        required
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className="w-full p-2 border rounded"
                                        required
                                    />
                                    <textarea
                                        placeholder="Your message"
                                        className="w-full p-2 border rounded"
                                        rows="4"
                                        required
                                    ></textarea>
                                    <button
                                        type="submit"
                                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                                    >
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </section>
                );
            case "security":
                return (
                    <section className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-semibold mb-6 flex items-center">
                            <FaShieldAlt className="mr-2 text-blue-500" />
                            Security Settings
                        </h2>
                        <div className="space-y-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-lg font-medium">
                                        Two-Factor Authentication
                                    </h3>
                                    <p className="text-gray-600">
                                        Add an extra layer of security to your account
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <label
                                        className={`relative inline-flex items-center ${
                                            twoFactorLoading
                                                ? "cursor-not-allowed"
                                                : "cursor-pointer"
                                        }`}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={twoFactor}
                                            onChange={handleTwoFactorAuth}
                                            disabled={twoFactorLoading}
                                            className="sr-only peer"
                                        />
                                        <div
                                            className={`
                                                w-11 h-6 
                                                rounded-full 
                                                peer 
                                                ${twoFactor ? "bg-blue-600" : "bg-gray-200"}
                                                after:content-[''] 
                                                after:absolute 
                                                after:top-[2px] 
                                                after:left-[2px] 
                                                after:bg-white 
                                                after:border-gray-300 
                                                after:border 
                                                after:rounded-full 
                                                after:h-5 
                                                after:w-5 
                                                after:transition-all
                                                ${twoFactor ? "after:translate-x-full" : ""}
                                                ${twoFactorLoading ? "opacity-50" : ""}
                                                peer-focus:outline-none 
                                                peer-focus:ring-4 
                                                peer-focus:ring-blue-300
                                            `}
                                        />
                                    </label>
                                    <span
                                        className={`ml-2 text-sm text-gray-600 ${
                                            twoFactorLoading ? "opacity-50" : ""
                                        }`}
                                    >
                                        {twoFactorLoading
                                            ? "Processing..."
                                            : twoFactor
                                            ? "Enabled"
                                            : "Disabled"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            default:
                return null;
        }
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Nav />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-8">Settings</h1>

                {/* Mobile Dropdown Menu */}
                <div className="md:hidden mb-6">
                    <button
                        onClick={toggleMobileMenu}
                        className="w-full flex items-center justify-between px-4 py-3 bg-white rounded-lg shadow-md"
                    >
                        <span className="font-medium">
                            {activeTab === "profile" && "Profile Information"}
                            {activeTab === "changePassword" && "Change Password"}
                            {activeTab === "notifications" && "Notification Preferences"}
                            {activeTab === "downloadData" && "Download Your Data"}
                            {activeTab === "paymentDetails" && "Payment Details"}
                            {activeTab === "security" && "Security Settings"}
                            {activeTab === "deactivateAccount" && "Deactivate Account"}
                            {activeTab === "help" && "Help & Support"}
                        </span>
                        <FaChevronDown
                            className={`transform transition-transform duration-200 ${
                                isMobileMenuOpen ? "rotate-180" : ""
                            }`}
                        />
                    </button>

                    {/* Mobile Menu Items */}
                    {isMobileMenuOpen && (
                        <div className="mt-2 py-2 bg-white rounded-lg shadow-lg absolute z-10 left-4 right-4">
                            <button
                                onClick={() => {
                                    setActiveTab("profile");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaUser /> Profile Information
                            </button>
                            <button
                                onClick={() => {
                                    setActiveTab("changePassword");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaShieldAlt /> Change Password
                            </button>
                            {/* Add similar buttons for other menu items */}
                            <button
                                onClick={() => {
                                    setActiveTab("notifications");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaBell /> Notification Preferences
                            </button>
                            <button
                                onClick={() => {
                                    setActiveTab("downloadData");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaEnvelope /> Download Your Data
                            </button>
                            <button
                                onClick={() => {
                                    setActiveTab("paymentDetails");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaCreditCard /> Payment Details
                            </button>
                            <button
                                onClick={() => {
                                    setActiveTab("security");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaShieldAlt /> Security Settings
                            </button>
                            <button
                                onClick={() => {
                                    setActiveTab("deactivateAccount");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaExclamationTriangle /> Deactivate Account
                            </button>
                            <button
                                onClick={() => {
                                    setActiveTab("help");
                                    setIsMobileMenuOpen(false);
                                }}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                            >
                                <FaExclamationTriangle /> Help & Support
                            </button>
                        </div>
                    )}
                </div>

                {/* Desktop and Mobile Layout */}
                <div className="flex flex-col md:flex-row gap-6">
                    {/* Sidebar - Hidden on mobile */}
                    <div className="hidden md:block md:w-1/4">
                        <nav className="flex flex-col space-y-2" aria-label="Tabs" role="tablist">
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "profile"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("profile")}
                            >
                                <FaUser /> Profile Information
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "changePassword"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("changePassword")}
                            >
                                <FaShieldAlt /> Password Update
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "notifications"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("notifications")}
                            >
                                <FaBell /> Notification Preferences
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "downloadData"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("downloadData")}
                            >
                                <FaEnvelope /> Download Your Data
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "paymentDetails"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("paymentDetails")}
                            >
                                <FaCreditCard /> Payment Details
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "security"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("security")}
                            >
                                <FaShieldAlt /> Security Settings
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "deactivateAccount"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("deactivateAccount")}
                            >
                                <FaExclamationTriangle /> Deactivate Account
                            </button>
                            <button
                                type="button"
                                className={`py-3 px-3 inline-flex items-center gap-2 text-sm font-medium text-center rounded-sm transition-colors duration-200 ${
                                    activeTab === "help"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                                }`}
                                onClick={() => setActiveTab("help")}
                            >
                                <FaExclamationTriangle /> Help & Support
                            </button>
                        </nav>
                    </div>

                    {/* Main Content - Full width on mobile */}
                    <div className="w-full md:w-3/4">{renderContent()}</div>
                </div>
            </div>

            {showPasswordForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="flex flex-col items-center bg-white rounded-lg p-8 max-w-md w-full">
                        {/* This part can be used for additional functionality if needed */}
                        <div className="font-medium pb-4">
                            Are you sure you want to change your password?
                        </div>
                        <div className="ml-40">
                            <button
                                type="button"
                                className="bg-blue-600 text-white px-4 py-2 rounded m-2"
                                onClick={handleConfirmChangePassword}
                            >
                                Proceed
                            </button>
                            <button
                                type="button"
                                className="bg-gray-500 text-white px-4 py-2 rounded m-2"
                                onClick={handleCancelChangePassword}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <TwoFactorModal
                isOpen={showTwoFactorModal}
                onClose={() => setShowTwoFactorModal(false)}
            />
            {showDeactivateModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg">
                        <h3 className="text-xl font-medium mb-4">
                            Are you sure you want to deactivate your account?
                        </h3>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                onClick={() => setShowDeactivateModal(false)}
                                className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={confirmDeactivateAccount}
                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                            >
                                Deactivate
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
