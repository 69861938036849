import React, { useState, useRef, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

import { getCountries } from "../services/apiService";

const CustomSelectCountry = ({ id, selectedOption, setSelectedOption, necessary }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [countries, setCountries] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        console.log("Selected option updated:", selectedOption);
    }, [selectedOption]);

    // const options = [
    //     { value: "KES", label: "Kenya", countryCode: "KE" },
    //     { value: "UGX", label: "Uganda", countryCode: "UG" },
    //     { value: "USD", label: "USA", countryCode: "US" },
    //     { value: "EUR", label: "Germany", countryCode: "DE" },
    //     { value: "GBP", label: "UK", countryCode: "GB" },
    //     { value: "EUR", label: "France", countryCode: "FR" }
    //     // Add more options as needed
    // ];

    useEffect(() => {
        const handleClickOutside = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                if (!selectedOption) {
                    setSearchTerm("");
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectedOption]);

    useEffect(() => {
        if (selectedOption) {
            setSearchTerm(selectedOption.country_name);
        }
    }, [selectedOption]);

    // get all countries
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries();
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            inputRef.current.focus();
        }
    };

    const handleOptionClick = option => {
        setSelectedOption(prevOption => {
            console.log("Previous selected option was:", prevOption);
            return option;
        });

        setSearchTerm(option.country_name);
        setIsOpen(false);
    };

    // const handleOptionClick = option => {
    //     setSelectedOption(option);
    //     setSearchTerm(option.country_name);
    //     setIsOpen(false);
    // };

    const handleInputChange = e => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value === "") {
            setSelectedOption(null);
        } else if (selectedOption) {
            setSelectedOption(null);
        }
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const filteredCountries = countries.filter(
        country => country.country_name.toLowerCase().includes(searchTerm.toLowerCase())
        // ||
        //     country.currency_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //     country.currency_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div className="flex">
                {selectedOption && necessary && (
                    <div className="flex items-center justify-center bg-gray-100 px-2 border border-r-0 border-gray-300 rounded-l-md">
                        {selectedOption.country_code}
                    </div>
                )}
                <input
                    ref={inputRef}
                    id={id}
                    type="text"
                    className={`w-full p-2 border border-gray-300 ${
                        selectedOption ? "rounded-r-md" : "rounded-md"
                    }`}
                    autoComplete="off"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={toggleDropdown}
                    placeholder="Select Country"
                    required
                />
            </div>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    <div className="max-h-60 overflow-y-auto">
                        {filteredCountries.map((option, index) => (
                            <div
                                key={index}
                                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                                onClick={() => handleOptionClick(option)}
                            >
                                {necessary ? (
                                    <>
                                        <ReactCountryFlag
                                            countryCode={option.country_code}
                                            svg
                                            className="mr-2"
                                            style={{
                                                width: "1.5em",
                                                height: "1.5em"
                                            }}
                                        />

                                        <span className="font-semibold mr-2">
                                            {option.currency_code}
                                        </span>

                                        {option.country_name}
                                    </>
                                ) : (
                                    <>{option.country_name}</>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelectCountry;
