import React, { useState } from "react";
import { FcApproval } from "react-icons/fc";

import { Container } from "../components/utils";
import { toast } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import { reactivateAccount } from "../services/apiService";

const AccountReactivation = () => {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const validateEmail = email => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
        setErrorMessage("");
    };

    const handleConfirmation = () => {
        if (!email) {
            setErrorMessage("Email address is required");
            return;
        }
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address");
            return;
        }
        setShowConfirmationModal(true);
    };

    const handleActivation = async () => {
        try {
            setShowConfirmationModal(false);
            const data = {
                email: email
            };
            await reactivateAccount(data);
            await new Promise(resolve => setTimeout(resolve, 1000));
            setShowFeedbackModal(true);
        } catch (error) {
            toast("error", "An error occurred while activating your account. Please try again.");
        }
    };

    const hideModal = () => {
        setShowFeedbackModal(false);
        setEmail("");
    };

    const FeedbackModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white p-8 rounded-md shadow-xl max-w-md w-full">
                <h3 className="text-xl font-semibold text-gray-700 mb-4">Account Activation</h3>
                <p className="text-gray-600 mb-6">
                    Please check your email for the activation link. Click the link to complete your
                    account activation.
                </p>
                <button
                    onClick={hideModal}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors w-full"
                >
                    Okay
                </button>
            </div>
        </div>
    );

    const ConfirmationModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white p-8 rounded-md shadow-xl max-w-md w-full">
                <h3 className="text-xl font-semibold text-gray-700 mb-4">Account Activation</h3>
                <p className="text-gray-600 mb-6">
                    If the email address belongs to a previously registered account, click proceed
                </p>
                <button
                    onClick={handleActivation}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors w-full mb-2"
                >
                    Proceed
                </button>
                <button
                    onClick={() => setShowConfirmationModal(false)}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors w-full"
                >
                    Edit
                </button>
            </div>
        </div>
    );

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container className={"flex items-center justify-center mb-60"}>
                    <div className="p-8 bg-white rounded-md shadow-md w-full">
                        <div className="flex flex-row justify-start mb-4 items-center h-full">
                            <div className="flex justify-center items-center w-8 h-8 rounded-full bg-blue-500 mr-2">
                                <FcApproval className="w-6 h-6" />
                            </div>
                            <h2 className="relative bottom-0 flex justify-center items-center h-8 text-xl font-semibold text-gray-700">
                                Account Activation
                            </h2>
                        </div>

                        <div className="mb-6 text-gray-600">
                            <p>
                                Welcome to our platform! To start using our services, you need to
                                activate your account.
                            </p>
                            <p className="mt-2">Activating your account will allow you to:</p>
                            <ul className="list-disc ml-6 mt-2">
                                <li>Access all features of our platform</li>
                                <li>Ensure the security of your account</li>
                                <li>Receive important updates and notifications</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <label
                                htmlFor="email"
                                className="block text-gray-700 font-semibold mb-2"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter your email"
                            />
                        </div>

                        {errorMessage && (
                            <div className="my-2 text-center text-red-600 bg-red-100 py-2 rounded-md">
                                {errorMessage}
                            </div>
                        )}

                        <button
                            onClick={handleConfirmation}
                            className="px-4 py-2 bg-blue-600 text-white mt-6 rounded-md hover:bg-blue-700 transition-colors w-full"
                        >
                            <div className="flex items-center justify-center flex-row">
                                Activate Your Account
                                <FcApproval className="ml-2 w-5 h-5" />
                            </div>
                        </button>
                    </div>
                </Container>
            </AdminLayout>
            {showConfirmationModal && <ConfirmationModal />}
            {showFeedbackModal && <FeedbackModal />}
        </>
    );
};

export default AccountReactivation;
