import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { React, useState, useEffect, useCallback } from "react";
import ReactCountryFlag from "react-country-flag";
import { Link, useNavigate } from "react-router-dom";

import CustomSelectCountry from "../components/CustomSelectCountry";
import CustomSelectRecipient from "../components/CustomSelectRecipient";
import NumberTextInput from "../components/NumberTextInput";
import { Container } from "../components/utils";
import { toast } from "../helpers";
import AdminLayout from "../layouts/AdminLayout";
import Nav from "../layouts/Nav";
import {
    getCountries,
    getExchangeRates,
    saveTransaction,
    calculateRemittance,
    getTransactionLimits
} from "../services/apiService";

// const transactionTypes = [
//     { value: "Bank Transfer", label: "Bank Transfer" },
//     { value: "Mobile Transfer", label: "Mobile Transfer" },
//     { value: "Cash Pickup", label: "Cash Pickup" }
//     // Add more transaction types as needed
// ];
const RemittanceForm = () => {
    const [selectedFromCountryOption, setSelectedFromCountryOption] = useState(null);
    const navigate = useNavigate();
    const [selectedToCountryOption, setSelectedToCountryOption] = useState(null);
    const [exchangeRates, setExchangeRates] = useState(null);
    const [selectedTransactionType, setSelectedTransactionType] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedRecipient, setSelectedRecipient] = useState(null);
    const [amountToSend, setAmountToSend] = useState("");
    const [amountToReceive, setAmountToReceive] = useState("");
    const [currentExchangeRate, setCurrentExchangeRate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isCalculating, setIsCalculating] = useState(false);
    const [transactionFee, setTransactionFee] = useState("0.00");
    const [debouncedAmountToSend, setDebouncedAmountToSend] = useState("");
    const [transactionLimits, setTransactionLimits] = useState({ minimum: 0, maximum: 0 });
    const [lastCalculatedAmount, setLastCalculatedAmount] = useState("");

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        const fetchExchangeRates = async () => {
            try {
                const response = await getExchangeRates();
                let exchangeRate = [];

                for (const [key, value] of Object.entries(response.data)) {
                    exchangeRate.push({ key, value });
                }
                exchangeRate.push({ key: "USDUSD", value: 1 });
                setExchangeRates(exchangeRate);
            } catch (error) {
                console.error(`exchange rate: ${error}`);
            }
        };
        fetchExchangeRates();
    }, []);

    useEffect(() => {
        if (selectedRecipient && countries) {
            const toCountry = countries.find(
                country => country.country_name?.trim() === selectedRecipient.country.trim()
            );
            if (toCountry) {
                setSelectedToCountryOption(toCountry);
            }
            // const transactionType = selectedRecipient.transaction_type;
            // setSelectedTransactionType(transactionType);
        }
    }, [selectedRecipient, countries]);

    useEffect(() => {
        const fetchTransactionLimits = async () => {
            if (selectedFromCountryOption) {
                const currency_from = selectedFromCountryOption.currency_code.trim();
                try {
                    const response = await getTransactionLimits(currency_from);
                    if (response.status === 200) {
                        setTransactionLimits({
                            minimum: response.data.minimum_amount,
                            maximum: response.data.maximum_amount
                        });
                    }
                } catch (error) {
                    setErrorMessage("Failed to fetch transaction limits");
                }
            }
        };

        fetchTransactionLimits();
    }, [selectedFromCountryOption]);

    const calculateAmount = useCallback(async () => {
        if (
            selectedFromCountryOption &&
            selectedToCountryOption &&
            amountToSend &&
            exchangeRates &&
            !isCalculating &&
            transactionLimits.minimum !== 0 &&
            transactionLimits.maximum !== 0 &&
            amountToSend !== lastCalculatedAmount
        ) {
            setIsCalculating(true);
            try {
                const amountInFromCurrency = parseFloat(amountToSend.replace(/,/g, ""));

                // Check if amount is within limits before making API call
                if (
                    amountInFromCurrency < transactionLimits.minimum ||
                    amountInFromCurrency > transactionLimits.maximum
                ) {
                    setErrorMessage(
                        `Amount must be between ${transactionLimits.minimum.toFixed(
                            2
                        )} and ${transactionLimits.maximum.toFixed(2)} ${
                            selectedFromCountryOption.currency_code
                        }`
                    );
                    setIsCalculating(false);
                    return;
                }

                // Clear error message if amount is within limits
                setErrorMessage("");

                // Proceed with API call for transaction fee
                const data = {
                    amount: amountToSend.replace(/,/g, ""),
                    currency_from: selectedFromCountryOption.currency_code.trim()
                };

                const response = await calculateRemittance(data);

                if (response.status === 200) {
                    setTransactionFee(response.transaction_fee.toFixed(2));
                    setLastCalculatedAmount(amountToSend);
                }

                // Original logic for amount to receive and exchange rate
                var fromCountryCurrencyCode = selectedFromCountryOption?.currency_code.trim();
                var toCountryCurrencyCode = selectedToCountryOption?.currency_code.trim();

                var fromExchangeRate = exchangeRates.find(
                    rate => rate.key === `USD${fromCountryCurrencyCode}`
                );

                var toExchangeRate = exchangeRates.find(
                    rate => rate.key === `USD${toCountryCurrencyCode}`
                );

                let convertAmountToSendToUsd = amountInFromCurrency / fromExchangeRate?.value;
                let convertUsdToAmountToReceive = convertAmountToSendToUsd * toExchangeRate?.value;

                parseFloat(convertUsdToAmountToReceive).toFixed(2) < 0
                    ? setAmountToReceive("0")
                    : setAmountToReceive(parseFloat(convertUsdToAmountToReceive).toFixed(2));

                setCurrentExchangeRate(
                    `1 ${selectedFromCountryOption?.currency_code} = ${parseFloat(
                        (1 / fromExchangeRate?.value) * toExchangeRate?.value
                    ).toFixed(2)} ${selectedToCountryOption?.currency_code}`
                );
            } catch (error) {
                setErrorMessage(`An error occurred while calculating remittance: ${error.message}`);
                setAmountToReceive("0.00");
            } finally {
                setIsCalculating(false);
            }
        }
    }, [
        selectedFromCountryOption,
        selectedToCountryOption,
        amountToSend,
        exchangeRates,
        isCalculating,
        transactionLimits,
        lastCalculatedAmount
    ]);

    useEffect(() => {
        calculateAmount();
    }, [calculateAmount]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedAmountToSend(amountToSend);
        }, 500);

        return () => clearTimeout(timer);
    }, [amountToSend]);

    useEffect(() => {
        if (selectedFromCountryOption && debouncedAmountToSend) {
            calculateAmount(debouncedAmountToSend, selectedFromCountryOption.currency_code);
        }
    }, [selectedFromCountryOption, debouncedAmountToSend, calculateAmount]);

    const validateForm = () => {
        let errors = {};

        if (!selectedFromCountryOption) errors.fromCountry = "From country is required, ";
        if (!selectedRecipient) errors.recipient = "Recipient is required, ";
        if (!selectedToCountryOption) errors.toCountry = "To country is required, ";
        if (!selectedTransactionType) errors.transactionType = "Transaction type is required, ";
        if (!amountToSend.trim()) errors.amountToSend = "Amount to send is required, ";
        else if (isNaN(parseFloat(amountToSend)) || parseFloat(amountToSend) <= 0)
            errors.amountToSend = "Amount to send must be a positive number, ";

        // Check if amount is within the limit
        const amountInFromCurrency = parseFloat(amountToSend.replace(/,/g, ""));
        if (transactionLimits.minimum !== 0 && transactionLimits.maximum !== 0) {
            if (
                amountInFromCurrency < transactionLimits.minimum ||
                amountInFromCurrency > transactionLimits.maximum
            ) {
                errors.amountToSend = `Amount must be between ${transactionLimits.minimum.toFixed(
                    2
                )} and ${transactionLimits.maximum.toFixed(2)} ${
                    selectedFromCountryOption.currency_code
                }`;
            }
        }

        if (isNaN(parseFloat(amountToReceive)) || parseFloat(amountToReceive) <= 0)
            errors.amountToReceive = "The amount entered is not remittable.";

        return errors;
    };

    const handleSubmit = async () => {
        const errorsOnForm = validateForm();

        if (Object.keys(errorsOnForm).length > 0) {
            let errorMessage = [];
            for (let key in errorsOnForm) {
                errorMessage.push(errorsOnForm[key]);
            }
            setErrorMessage(errorMessage);
            return;
        }

        const remittance = {
            from_country: selectedFromCountryOption?.country_name.trim(),
            recipient_id: selectedRecipient.recipient_id,
            to_country: selectedToCountryOption?.country_name.trim(),
            transaction_type: selectedTransactionType,
            amount_sent: amountToSend.replace(/,/g, ""),
            amount_sent_currency: selectedFromCountryOption?.currency_code.trim(),
            amount_received: amountToReceive,
            amount_received_currency: selectedToCountryOption?.currency_code.trim(),
            transfer_fee: transactionFee,
            exchange_rate: currentExchangeRate
        };

        try {
            const response = await saveTransaction(remittance);
            if (response.status === 200) {
                toast("success", response.description);
                navigate("/dashboard");
            } else {
                setErrorMessage(response.description);
            }
        } catch (error) {
            if (error.description) {
                var errors = [];
                for (var description in error.description) {
                    errors.push(description);
                    setErrorMessage(errors.join(", "));
                }
            } else {
                setErrorMessage("An unexpected error occurred");
            }
        }
    };

    return (
        <>
            <Nav />
            <AdminLayout>
                <Container>
                    <div className="w-3/12 mb-4 flex items-center justify-start">
                        <Link to={"/dashboard"}>
                            <h2 className="text-xl hover:text-blue-500 text-gray-500 flex flex-row items-center justify-start">
                                <ArrowBackIcon className="mr-2" />
                                Dashboard
                            </h2>
                        </Link>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <div className="w-full md:w-1/2 bg-white p-4 md:p-6 rounded-md shadow-md mb-8 md:mb-0">
                            <h2 className="mb-4 text-xl font-semibold text-blue-700">
                                Remittance Form
                            </h2>

                            <form onSubmit={e => e.preventDefault()}>
                                <div className="mb-4 w-full">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="fromCountry"
                                    >
                                        From Country*
                                    </label>
                                    <CustomSelectCountry
                                        id={"fromCountry"}
                                        selectedOption={selectedFromCountryOption}
                                        setSelectedOption={setSelectedFromCountryOption}
                                        necessary={true}
                                    />
                                </div>

                                <div className="mb-4 w-full">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="recipient"
                                    >
                                        Recipient*
                                    </label>
                                    <div className="flex items-center">
                                        <CustomSelectRecipient
                                            selectedOption={selectedRecipient}
                                            setSelectedOption={setSelectedRecipient}
                                        />

                                        <Link to={"/recipients/add"} state={{ returnTo: true }}>
                                            <div
                                                className="flex flex-row items-center justify-center bg-blue-500 hover:bg-blue-700 text-white ml-1 font-bold py-2 px-4 rounded-md"
                                                style={{ cursor: "pointer" }}
                                            >
                                                Add
                                                <div className="ml-1">
                                                    <AddCircleOutlineIcon />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className="mb-4 w-full">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="toCountry"
                                    >
                                        To Country*
                                    </label>
                                    <div className="flex">
                                        {selectedToCountryOption && (
                                            <div className="flex items-center justify-center bg-gray-100 px-2 border border-r-0 border-gray-300 rounded-l-md">
                                                {selectedToCountryOption.country_code}
                                            </div>
                                        )}
                                        <input
                                            id={"toCountry"}
                                            type="text"
                                            className={`w-full p-2 border border-gray-300 ${
                                                selectedToCountryOption
                                                    ? "rounded-r-md"
                                                    : "rounded-md"
                                            }`}
                                            autoComplete="off"
                                            value={
                                                selectedToCountryOption
                                                    ? selectedToCountryOption?.country_name
                                                    : ""
                                            }
                                            placeholder="Select Country"
                                            readOnly
                                            // disabled
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                        htmlFor="transactionType"
                                    >
                                        Transaction Type*
                                    </label>
                                    <select
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="transactionType"
                                        value={selectedTransactionType}
                                        onChange={e => setSelectedTransactionType(e.target.value)}
                                        placeholder="Select Transaction Type"
                                        // readOnly
                                        required
                                    >
                                        <option value="">Select Transaction Type</option>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="Mobile Transfer">Mobile Transfer</option>
                                    </select>
                                </div>

                                <div className="mb-5 flex flex-col md:flex-row justify-between items-center">
                                    <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-1">
                                        <label
                                            className="block mb-2 font-semibold text-gray-700"
                                            htmlFor="sendingAmount"
                                        >
                                            Amount To Send*
                                        </label>
                                        <div className="flex items-center border text-gray-600  bg-blue-500 border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500">
                                            <NumberTextInput
                                                placeholder={"Enter Amount"}
                                                className={
                                                    "w-full px-3 py-2 border text-black border-gray-300 rounded-l-md "
                                                }
                                                formattedValue={amountToSend}
                                                setFormattedValue={setAmountToSend}
                                            />

                                            <div className="flex items-center justify-center w-1/6">
                                                {selectedFromCountryOption
                                                    ? selectedFromCountryOption.currency_code
                                                    : "CUR"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 mb-4 md:mb-0 md:ml-1">
                                        <label
                                            className="block mb-2 font-semibold text-gray-700"
                                            htmlFor="amountToReceive"
                                        >
                                            Amount To Receive*
                                        </label>
                                        <div className="flex items-center border text-gray-600  bg-blue-500 border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500">
                                            <NumberTextInput
                                                placeholder={"Enter Amount"}
                                                className={
                                                    "w-full px-3 py-2 border text-black border-gray-300 rounded-l-md "
                                                }
                                                formattedValue={amountToReceive}
                                                setFormattedValue={setAmountToReceive}
                                                readOnly={true}
                                            />
                                            <div className="flex items-center justify-center w-1/6">
                                                {selectedToCountryOption
                                                    ? selectedToCountryOption.currency_code
                                                    : "CUR"}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-7">
                                    <div className="flex flex-col md:flex-row justify-between mb-3">
                                        <p className="text-sm mb-3 md:mb-0 text-blue-700">
                                            Limit {transactionLimits.minimum.toFixed(2)} -{" "}
                                            {transactionLimits.maximum.toFixed(2)}{" "}
                                            {selectedFromCountryOption?.currency_code}
                                        </p>
                                        <p className="text-sm text-blue-700">
                                            Charge: {transactionFee}{" "}
                                            {selectedFromCountryOption?.currency_code || "CUR"}
                                        </p>
                                    </div>
                                    <p className="text-sm text-blue-700 mb-3">
                                        {currentExchangeRate}
                                    </p>
                                </div>

                                <div className="flex text-red-500 items-center text-center mb-4 justify-center">
                                    {errorMessage ? errorMessage : ""}
                                </div>

                                <button
                                    onClick={handleSubmit}
                                    className="w-full flex flex-row justify-center cursor-pointer bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-2 px-4 rounded-md"
                                >
                                    Continue
                                    <div className="ml-2">
                                        <SendIcon />
                                    </div>
                                </button>
                            </form>
                        </div>

                        <div className="w-full rounded md:w-1/2 bg-white p-4 md:p-6 shadow-md">
                            <h2 className="mb-4 text-xl font-semibold text-blue-700">Preview</h2>

                            <div className="p-4 rounded-t flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">From Country</span>
                                <span className="font-semibold text-gray-700">
                                    {selectedFromCountryOption
                                        ? selectedFromCountryOption?.country_name.trim()
                                        : "Not Selected"}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">To Country</span>
                                <span className="font-semibold text-gray-700">
                                    {selectedToCountryOption
                                        ? selectedToCountryOption?.country_name.trim()
                                        : "Not Selected"}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">
                                    Transaction Type
                                </span>
                                <span className="font-semibold text-gray-700">
                                    {selectedTransactionType
                                        ? selectedTransactionType
                                        : "Not Selected"}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">Recipient</span>
                                <span className="font-semibold text-gray-700">
                                    {selectedRecipient
                                        ? `${selectedRecipient.first_name} ${selectedRecipient.last_name}`
                                        : "Not Selected"}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">Amount To Send</span>
                                <span className="font-semibold text-gray-700">
                                    {amountToSend && selectedFromCountryOption
                                        ? `${amountToSend} ${selectedFromCountryOption?.currency_code.trim()}`
                                        : "Not Selected"}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">Transfer Fee</span>
                                <span className="font-semibold text-gray-700">
                                    {transactionFee} {selectedFromCountryOption?.currency_code}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">Exchange Rate</span>
                                <span className="font-bold">
                                    {currentExchangeRate ? currentExchangeRate : "Pending"}
                                </span>
                            </div>

                            <div className="p-4 flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">
                                    Amount To Receive
                                </span>
                                <span className="font-bold text-blue-700">
                                    {amountToReceive && selectedToCountryOption
                                        ? `${amountToReceive} ${selectedToCountryOption?.currency_code}`
                                        : "Not Selected"}
                                </span>
                            </div>

                            <div className="p-4 rounded-b flex border border-gray-300 items-center justify-between">
                                <span className="font-semibold text-gray-700">Total Payable</span>
                                <span className="font-bold text-blue-700">
                                    {amountToReceive && selectedToCountryOption
                                        ? `${parseFloat(
                                              parseFloat(amountToSend.replace(/,/g, "")) +
                                                  parseFloat(transactionFee)
                                          ).toFixed(2)} ${
                                              selectedFromCountryOption
                                                  ? selectedFromCountryOption?.currency_code
                                                  : "CUR"
                                          }`
                                        : "Not Selected"}
                                </span>
                            </div>
                        </div>
                    </div>
                </Container>
            </AdminLayout>
        </>
    );
};

export default RemittanceForm;
