import { Result, Button } from "antd";
import React from "react";
import { useLocation, Link } from "react-router-dom";

const VerificationPending = () => {
    const location = useLocation();
    const { name, email } = location.state || {};

    return (
        <Result
            status="info"
            title="Verification Pending"
            subTitle={`Thank you for registering, ${name}. Your account is currently pending verification. We'll send an email to ${email} once the verification process is complete.`}
            extra={[
                <Button type="primary" key="home">
                    <Link to="/">Return to Home</Link>
                </Button>
            ]}
        />
    );
};

export default VerificationPending;
